import Vue from 'vue'
import { verifyAndRegister, smsRegister, MemberInfo, loginOut, getEquityType, getMemberInfo, reportAnalytics } from '@/services/userApi'
import { getLocal, setLocal, clearLocal } from '@/utils/common/utils'

import { Toast } from 'vant'
import { isWeChat } from '@/utils/common/validate'

Vue.use(Toast)
const state = {
  token: getLocal('token') || '',
  uid: getLocal('nickname') || '',
  userarvr: getLocal('userarvr') || '',
  username: getLocal('username') || '',
  nickname: getLocal('nickname') || '',
  levelText: getLocal('levelText') || '',
  codeFand: getLocal('codeFand') || '',
  headImg: getLocal('headImg') || ''
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_UID: (state, uid) => {
    state.uid = uid
  },
  SET_NICKNAME: (state, nickname) => {
    state.nickname = nickname
  },
  SET_USERARVR: (state, userarvr) => {
    state.userarvr = userarvr
  },
  SET_USERNAME: (state, name) => {
    state.username = name
  },
  SET_LEVELTEXT: (state, levelText) => {
    state.levelText = levelText
  },
  SET_CODEFAND: (state, codeFand) => {
    state.codeFand = codeFand
  },
  SET_HEADIMG: (state, headImg) => {
    state.headImg = headImg
  },
  SET_ISFOLLW: (state, isFollow) => {
    state.isFollow = isFollow
  }

}

const actions = {
  // user login

  // 手机验证码登录注册
  login({ commit }, userInfo) {
    if (!userInfo.mobile) {
      Toast('请输入正确手机号')
      return
    }
    if (!userInfo.code) {
      Toast('验证码不正确')
      return
    }
    clearLocal()
    sessionStorage.removeItem('verifyCode')
    return new Promise((resolve, reject) => {
      verifyAndRegister(userInfo).then(res => {
        // console.log(res)
        if (Number(res.code) === 200) {
          // return
          commit('SET_TOKEN', res.token)
          commit('SET_UID', res.uid)
          commit('SET_NICKNAME', res.nickname)
          setLocal('token', res.token)
          setLocal('uid', res.mid)
          setLocal('mobile', res.mobile)
          setLocal('userInfo', JSON.stringify(res))
          setLocal('openid', res.openid)
          setLocal('md5Id', res.md5Id)
          setLocal('recode', res.recode)
          setLocal('nickname', res.nickname)
          setLocal('SearchHistory', JSON.stringify([]))

          if (res.phone_code) {
            sessionStorage.setItem('verifyCode', res.phone_code)
          }
          // get用户会员类型
          getEquityType({
            mid: res.mid
          }).then(res => {
            if (Number(res.code) === 200) { // 存储用户会员类型  0注册会员  1 金卡会员 2 白金会员 3 黑金会员
              window.localStorage.setItem('memberType', res.data.memberType)
            } else {
              Toast(res.msg)
            }
          })
        }
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  // 邮箱注册
  smsRegister({ commit }, userInfo) {
    clearLocal()
    sessionStorage.removeItem('verifyCode')
    return new Promise((resolve, reject) => {
      smsRegister(userInfo).then(res => {
        if (Number(res.code) === 200) {
          commit('SET_TOKEN', res.token)
          commit('SET_UID', res.id)
          commit('SET_NICKNAME', res.nickname)
          setLocal('token', res.token)
          setLocal('uid', res.mid)
          setLocal('mobile', res.mobile)
          setLocal('userInfo', JSON.stringify(res))
          setLocal('openid', res.openid)
          setLocal('md5Id', res.md5Id)
          setLocal('recode', res.recode)
          setLocal('nickname', res.nickname)
          if (res.phone_code) {
            sessionStorage.setItem('verifyCode', res.phone_code)
          }
        }
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      MemberInfo().then(res => {
        commit('SET_NICKNAME', res.data.nickName)

        commit('SET_USERARVR', res.data.avatar)
        commit('SET_USERNAME', res.data.name)
        commit('SET_LEVELTEXT', res.data.levelText)
        commit('SET_CODEFAND', res.data.codeFand)
        commit('SET_HEADIMG', res.data.headImg)
        setLocal('userarvr', res.data.avatar)
        setLocal('username', res.data.name)
        setLocal('headImg', res.data.avatar)
        setLocal('levelText', res.data.levelText)
        setLocal('codeFand', res.data.reCode)

        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  // 退出登录
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      clearLocal()
      commit('SET_TOKEN', '')
      commit('SET_UID', '')
      commit('SET_NICKNAME', '')
      commit('SET_USERARVR', '')
      commit('SET_USERNAME', '')
      commit('SET_LEVELTEXT', '')
      commit('SET_CODEFAND', '')
      commit('SET_HEADIMG', '')

      loginOut().then(res => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  // 检查是否关注过微信公众号
  isFollowChange({ commit, state }) {
    const params = {
      platform: 1,
      openid: getLocal('openid')
    }

    return new Promise((resolve, reject) => {
      if (isWeChat()) {
        if (getLocal('openid')) {
          getMemberInfo(params).then(res => {
            if (Number(res.code) === 200) {
              if (res.data.subscribe === 1) {
                resolve(false)
              } else {
                resolve(true)
              }
            }
          })
        } else {
          resolve(true)
        }
      } else {
        resolve(false)
      }
    })
  },
  // 获取注册人的设备信息
  reportAnalytics({ commit }, data) {
    return new Promise((resolve, reject) => {
      reportAnalytics(data).then(res => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  }

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
