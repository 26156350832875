// javascript coding=utf-8
/*
 * @Author: Chen-
 * @Description: 头部注释
 * @Date: 2020-11-05 16:34:09
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-05-30 10:38:11
 * @FilePath: /yue_quanzhan_h5/src/routers/moudles/localLife.js
 */
export default [
  {
    path: '/homeIndex',
    name: 'homeIndex',
    component: () =>
      import ('@/views/localLife/homeIndex'),
    meta: {
      title: '本地生活首页',
      requireAuth: true
    }
  },
  {
    path: '/comeOnGuide',
    name: 'comeOnGuide',
    redirect: '/refueling-list',
    component: () =>
      import ('@/views/localLife/comeOn/comeOnGuide'),
    meta: {
      title: '加油引导页',
      requireAuth: true
    }
  },
  {
    path: '/comeOnHelp',
    name: 'comeOnHelp',
    component: () =>
      import ('@/views/localLife/comeOn/comeOnHelp'),
    meta: {
      title: '帮助&客服'
    }
  },
  {
    path: '/comeOnDataList',
    name: 'comeOnDataList',
    redirect: '/refueling-list',
    meta: {
      title: '省钱加油站'
    }
  },
  {
    path: '/oilConponIndex',
    name: 'oilConponIndex',
    component: () =>
      import ('@/views/localLife/comeOn/h5/conpon/index'),
    meta: {
      title: '领券'
      // requireAuth: true
    }
  },
  {
    path: '/oilConponDetail',
    name: 'oilConponDetail',
    component: () =>
      import ('@/views/localLife/comeOn/h5/conpon/detail'),
    meta: {
      title: '领券加油'
      // requireAuth: true
    }
  },
  {
    path: '/comeOnDataInfo',
    name: 'comeOnDataInfo',
    component: () =>
      import ('@/views/localLife/comeOn/comeOnDatainfo'),
    meta: {
      title: '油站详情',
      requireAuth: false
    }
  },
  {
    path: '/AmountSubmitted',
    name: 'AmountSubmitted',
    component: () =>
      import ('@/views/localLife/comeOn/AmountSubmitted'),
    meta: {
      title: '输入金额',
      requireAuth: false
    }
  },
  {
    path: '/paymentok',
    name: 'paymentok',
    component: () =>
      import ('@/views/localLife/comeOn/paymentok'),
    meta: {
      title: '支付成功',
      requireAuth: false
    }
  },
  {
    path: '/PreferentialRules',
    name: 'PreferentialRules',
    component: () =>
      import ('@/views/localLife/comeOn/PreferentialRules'),
    meta: {
      title: '加油结算优惠规则'
    }
  },
  {
    path: '/Confirmorder',
    name: 'Confirmorder',
    component: () =>
      import ('@/views/localLife/comeOn/Confirmorder'),
    meta: {
      title: '确认订单',
      requireAuth: false
    }
  },
  {
    path: '/TopUpIndex',
    name: 'TopUpIndex',
    component: () =>
      import ('@/views/localLife/lifeTopUp/TopUpIndex'),
    meta: {
      title: '生活充值',
      requireAuth: true
    }
  },
  {
    path: '/videoTopUp',
    name: 'videoTopUp',
    component: () =>
      import ('@/views/localLife/lifeTopUp/videoTopUp'),
    meta: {
      title: '视频充值',
      requireAuth: true
    }
  }, {
    path: '/videoDetails',
    name: 'videoDetails',
    component: () =>
      import ('@/views/localLife/Order/videoDetail'),
    meta: {
      title: '视频详情',
      requireAuth: true
    }
  },
  {
    path: '/UsageMethod',
    name: 'UsageMethod',
    component: () =>
      import ('@/views/localLife/comeOn/UsageMethod'),
    meta: {
      title: ''
    }
  },
  {
    path: '/privileges',
    name: 'privileges',
    component: () =>
      import ('@/views/localLife/comeOn/privileges'),
    meta: {
      title: ''
    }
  },
  {
    path: '/loanIndex',
    name: 'loanIndex',
    component: () =>
      import ('@/views/localLife/loan/loanIndex'),
    meta: {
      title: '我要贷款',
      requireAuth: true
    }
  },
  {
    path: '/serviceIndex',
    name: 'serviceIndex',
    component: () =>
      import ('@/views/localLife/loan/serviceIndex'),
    meta: {
      title: '我要贷款服务协议',
      requireAuth: false
    }
  },
  // 信用卡
  {
    path: '/Credit',
    name: 'Credit',
    component: () => import('@/views/localLife/creditCard/Credit'),
    children: [{
      path: '',
      name: 'Credit',
      redirect: '/Credit/creditcardapply',
      component: () => import('@/views/localLife/creditCard/Credit'),
      meta: {
        title: '信用卡申请',
        requiresAuth: true
      },
      children: [
        {
          path: 'Creditcardapply',
          name: 'Creditcardapply',
          component: () => import('@/views/localLife/creditCard/creditcardapply'),
          meta: { title: '金融服务', requireAuth: true }
        }, {
          path: 'ApplicationRecord',
          name: 'ApplicationRecord',
          component: () => import('@/views/localLife/creditCard/ApplicationRecord'),
          meta: { title: '金融服务订单', requireAuth: true }
        }
      ]
    }]
  },
  {
    path: '/applicantInfo',
    name: 'applicantInfo',
    component: () => import('@/views/localLife/creditCard/applicantInfo'),
    meta: { title: '申请人完善信息', requireAuth: false }
  },
  {
    path: '/singleCardShare',
    name: 'singleCardShare',
    component: () => import('@/views/localLife/creditCard/singleCardShare'),
    meta: { title: '单卡分享', requireAuth: true }
  },
  {
    path: '/cardServiceIndex',
    name: 'cardServiceIndex',
    component: () => import('@/views/localLife/creditCard/cardServiceIndex'),
    meta: { title: '信用卡服务协议', requireAuth: false }
  },
  {
    path: '/Rewardrules',
    name: 'Rewardrules',
    component: () =>
      import('@/views/localLife/creditCard/Rewardrules'),
    meta: {
      title: '奖励规则',
      requireAuth: false
    }
  },
  {
    path: '/moreBank',
    name: 'moreBank',
    component: () =>
      import('@/views/localLife/creditCard/moreBank'),
    meta: {
      title: '更多信用卡',
      requireAuth: false
    }
  },
  {
    path: '/infoCollection',
    name: 'infoCollection',
    component: () =>
      import('@/views/localLife/creditCard/infoCollection'),
    meta: {
      title: '申请人信息',
      requireAuth: false
    }
  },
  {
    path: '/Creditcardinfo',
    name: 'Creditcardinfo',
    component: () =>
      import('@/views/localLife/creditCard/Creditcardinfo'),
    meta: {
      title: '详情',
      requireAuth: false
    }
  },
  {
    path: '/infoposter',
    name: 'infoposter',
    component: () =>
      import('@/views/localLife/creditCard/infoposter'),
    meta: {
      title: '全卡种推广海报'
    }
  },
  {
    path: '/BrandDiscountIndex',
    name: 'BrandDiscountIndex',
    component: () =>
      import('@/views/localLife/BrandDiscount/BrandDiscountIndex'),
    meta: {
      title: '品牌折扣'
    }
  },
  {
    path: '/memberIndex',
    name: 'memberIndex',
    component: () =>
      import('@/views/localLife/memberIndex'),
    meta: {
      title: '会员权益'
    }
  },
  {
    path: '/Cinematicket_poster',
    name: 'Cinematicket_poster',
    component: () =>
      import('@/views/localLife/Film/Cinematicket_poster'),
    meta: {
      title: '电影票',
      requireAuth: false
    }
  },
  {
    path: '/MovieTickets',
    name: 'MovieTickets',
    component: () =>
      import('@/views/localLife/Film/MovieTickets'),
    meta: {
      title: '电影票',
      requireAuth: false
    }
  },
  {
    path: '/goMovie',
    name: 'goMovie',
    component: () =>
      import('@/views/localLife/Film/goMovie'),
    meta: {
      title: '购买电影票',
      requireAuth: false
    }
  },
  {
    path: '/comeOn_Order',
    name: 'comeOn_Order',
    component: () =>
      import('@/views/localLife/Order/comeOn_Order'),
    meta: {
      title: '加油订单'
    }
  },
  {
    path: '/lifeServiceOrder',
    name: 'lifeServiceOrder',
    component: () =>
      import('@/views/localLife/Order/orderList'),
    meta: {
      title: '订单',
      requireAuth: true
    }
  },
  {
    path: '/goFilm',
    name: 'goFilm',
    component: () =>
      import('@/views/localLife/Film/goFilm'),
    meta: {
      title: '电影票'
      // requireAuth: true
    }
  }, {
    path: '/FilmDomesticDetail',
    name: 'FilmDomesticDetail',
    component: () =>
      import('@/views/localLife/Film/filmDomesticDetail'),
    meta: {
      title: '电影票分佣详情',
      requireAuth: false
    }
  }, {
    path: '/KingCardIndex',
    name: 'KingCardIndex',
    component: () =>
      import('@/views/localLife/KingCard/index'),
    meta: {
      title: '腾讯大王卡',
      requireAuth: true
    }
  }, {
    path: '/infoFill',
    name: 'infoFill',
    component: () =>
      import('@/views/localLife/KingCard/infoFill'),
    meta: {
      title: '腾讯大王卡信息填写',
      requireAuth: true
    }
  }, {
    path: '/ChoiceNumber',
    name: 'ChoiceNumber',
    component: () =>
      import('@/views/localLife/KingCard/ChoiceNumber'),
    meta: {
      title: '腾讯大王卡选择号码',
      requireAuth: false
    }
  }, {
    path: '/SubmitSuccess',
    name: 'SubmitSuccess',
    component: () =>
      import('@/views/localLife/KingCard/SubmitSuccess'),
    meta: {
      title: '腾讯大王卡提交成功',
      requireAuth: false
    }
  }, {
    path: '/HomeServiceAgreement',
    name: 'HomeServiceAgreement',
    component: () =>
      import('@/views/localLife/KingCard/HomeService'),
    meta: {
      title: '客户入网服务协议及业务协议',
      requireAuth: false
    }
  }, {
    path: '/infoServiceAnnouncement',
    name: 'infoServiceAnnouncement',
    component: () =>
      import('@/views/localLife/KingCard/infoService'),
    meta: {
      title: '关于客户个人信息收集、使用规则的公告',
      requireAuth: false
    }
  }, {
    path: '/hungry',
    name: 'hungry',
    component: () =>
      import('@/views/localLife/hungry/index'),
    meta: {
      title: '饿了么',
      requireAuth: false
    }
  }, {
    path: '/publicity',
    name: 'publicity',
    component: () =>
      import('@/views/localLife/creditCard/creditCardPDF'),
    meta: {
      title: '信用卡宣传物料PDF',
      requireAuth: false
    }
  }, {
    path: '/TicketOrder',
    name: 'TicketOrder',
    component: () =>
      import('@/views/localLife/Order/TicketOrder'),
    meta: {
      title: '机票订单',
      requireAuth: false
    }
  }, {
    path: '/ticketOrderDetail',
    name: 'ticketOrderDetail',
    component: () =>
      import('@/views/localLife/Order/ticketOrderDetail'),
    meta: {
      title: '机票订单详情',
      requireAuth: false
    }
  }, {
    path: '/TrainOrder',
    name: 'TrainOrder',
    component: () =>
      import('@/views/localLife/Order/TrainOrder'),
    meta: {
      title: '火车票订单',
      requireAuth: false
    }
  }, {
    path: '/ticketPay',
    name: 'ticketPay',
    component: () =>
      import('@/views/localLife/Ticket/index'),
    meta: {
      title: '机票',
      requireAuth: true
    }
  }, {
    path: '/tickedList',
    name: 'tickedList',
    component: () =>
      import('@/views/localLife/Ticket/tickedList'),
    meta: {
      title: '机票列表',
      requireAuth: true
    }
  }, {
    path: '/RoundTripTickedList',
    name: 'RoundTripTickedList',
    component: () =>
      import('@/views/localLife/Ticket/RoundTripTickedList'),
    meta: {
      title: '往返机票列表',
      requireAuth: true
    }
  }, {
    path: '/tickedDetail',
    name: 'tickedDetail',
    component: () =>
      import('@/views/localLife/Ticket/tickedDetail'),
    meta: {
      title: '行程详情',
      requireAuth: true
    }
  }, {
    path: '/reservationTicket',
    name: 'reservationTicket',
    component: () =>
      import('@/views/localLife/Ticket/reservationTicket'),
    meta: {
      title: '机票预定',
      requireAuth: true
    }
  }, {
    path: '/addOpp',
    name: 'addOpp',
    component: () =>
      import('@/views/localLife/Ticket/addOpp'),
    meta: {
      title: '添加乘机人',
      requireAuth: true
    }
  },
  {
    path: '/selOpp',
    name: 'selOpp',
    component: () =>
      import('@/views/localLife/Ticket/selOpp'),
    meta: {
      title: '选择乘机人',
      requireAuth: true
    }
  }, {
    path: '/roundTripTickedDetail',
    name: 'roundTripTickedDetail',
    component: () =>
      import('@/views/localLife/Ticket/roundTripTickedDetail'),
    meta: {
      title: '往返详情',
      requireAuth: true
    }
  }, {
    path: '/returnReservationTicket',
    name: 'returnReservationTicket',
    component: () =>
      import('@/views/localLife/Ticket/returnReservationTicket'),
    meta: {
      title: '往返详情',
      requireAuth: true
    }
  }, {
    path: '/ticketChange',
    name: 'ticketChange',
    component: () =>
      import('@/views/localLife/Ticket/ticketChange'),
    meta: {
      title: '机票改签',
      requireAuth: true
    }
  }, {
    path: '/ticketRefund',
    name: 'ticketRefund',
    component: () =>
      import('@/views/localLife/Ticket/ticketRefund'),
    meta: {
      title: '机票退票',
      requireAuth: true
    }
  }, {
    path: '/refundConfirmation',
    name: 'refundConfirmation',
    component: () =>
      import('@/views/localLife/Ticket/refundConfirmation'),
    meta: {
      title: '机票退票确认',
      requireAuth: true
    }
  }, {
    path: '/changeChoiceAcc',
    name: 'changeChoiceAcc',
    component: () =>
      import('@/views/localLife/Ticket/changeChoiceAcc'),
    meta: {
      title: '机票改签选择舱位',
      requireAuth: true
    }
  }, {
    path: '/authorizationInfo',
    name: 'authorizationInfo',
    component: () =>
      import('@/views/localLife/authorizationInfo'),
    meta: {
      title: '用户授权'
      // requireAuth: true
    }
  }, {
    path: '/agreement',
    name: 'agreement',
    component: () =>
      import('@/views/localLife/agreement'),
    meta: {
      title: '用户授权协议'
      // requireAuth: true
    }
  },

  //    生活充值测试路由
  {
    path: '/test-topUpIndex',
    name: '/test-topUpIndex',
    component: () =>
      import('@/views/localLife/lifeTopUp/TopUpIndex'),
    meta: {
      title: '生活充值首页测试地址',
      requireAuth: true
    }
  }, {
    path: '/test-placeOder',
    name: 'test-placeOder',
    component: () =>
      import('@/views/user/placeOder'),
    meta: {
      title: '生活充值支付测试地址',
      requireAuth: true
    }
  },
  {
    path: '/kingdetail',
    name: 'kingdetail',
    component: () => import('@/views/localLife/KingCard/kingcard_detail'),
    meta: {
      title: '活动详情',
      requireAuth: true
    }
  },
  {
    path: '/refueling-confirm-detail',
    name: 'RefuelingConfirmDetail',
    component: () => import('@/views/localLife/refueling/confirm-detail'),
    meta: {
      title: '确认订单',
      requireAuth: true
    }
  },
  {
    path: '/refueling-list',
    name: 'RefuelingList',
    component: () => import('@/views/localLife/refueling/list'),
    meta: {
      title: '省钱加油站',
      requireAuth: true
    }
  }
]
