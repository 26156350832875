const getters = {

  token: state => state.user.token,
  uid: state => state.user.uid,
  userarvr: state => state.user.userarvr,
  username: state => state.user.username,
  nickname: state => state.user.nickname,
  levelText: state => state.user.levelText,
  codeNumber: state => state.codeNumber,
  headImg: state => state.user.headImg,
  codeFand: state => state.user.codeFand

}
export default getters
