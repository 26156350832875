/**
 * App交互相关
 */
import qs from 'qs'
import { isWeChat, isWeChatApplet, isYTApp } from '@/utils/common/validate'

/**
 * 触发APP方法配置
 * 2021-06-03 14:28:09
 * @author SnowRock
 * @param params 传入App或小程序参数集合
 * @param params.eventName {'share'|'login'|'isShowShare'|'share_cake'|'back' } 触发App方法事件名称
 * <br> share: 分享, login: 触发登录功能, isShowShare: 是否打开App右上角分享按钮 , share_cake: 触发分享微信好友及分享朋友圈功能,
 * back: YC加油站列表参数信息
 * @param params.path 跳转的APP地址 或者 share 下小程序专用参数 APP嵌入的H5活动页分享出去的小程序路径（如果有次参数分享url参数则不生效）
 * @param params.url 分享的微信后链接地址
 * @param params.image 分享到微信的图片
 * @param params.title 分享到微信的title
 * @param params.des 分享到微信后的摘要
 * @param params.isShow isShowShare 下是否展示右上角分享开关 1 开启 0 关闭
 * @param params.posterText 悦淘瓜分活动朋友圈海报上特殊的文案
 * @param params.posterImage 悦淘瓜分活动海报背景
 * @param params.name 跳转H5页面必填参数
 * @param params.redirectType 跳转H5页面跳转类型
 * @param params.redirectUrl 跳转H5页面需要跳转的链接地址
 */
export function routerRN(params) {
  // 判断是否在微信小程序中
  if (isWeChat() && isWeChatApplet()) {
    // 微信小程序
    params.goodsId = params.id
    params.productType = params.redirectType
    const qsStr = qs.stringify(params)
    let wxUrl = null

    switch (params.productType) {
      case 1:
        // 跳转自营商品详情
        wxUrl = '/page/Yuemall/pages/details/details?' + qsStr
        break
      case 3:
        // 京东商品详情
        wxUrl =
          '/page/Yuemall/pages/JDUnionDetail/JDUnionDetail?' + qsStr
        break
    }

    if (wxUrl) {
      // eslint-disable-next-line no-undef
      wx.miniProgram.navigateTo({
        url: wxUrl
      })
    }
  } else {
    if (
      window.webkit &&
      window.webkit.messageHandlers &&
      window.webkit.messageHandlers.NativeMethod
    ) {
      // 1
      params.CShopId = params.cshopId
    }
    useAppMethods(params)
  }
}

/**
 * 使用App的方法文档
 * 2021-07-27 16:18:18
 * @author SnowRock
 * @param params 传入App参数集合
 * @param params.eventName {'share'|'login'|'isShowShare'|'share_cake'|'back' } 触发App方法事件名称
 * <br> share: 分享, login: 触发登录功能,无需传任何参数 isShowShare: 是否打开App右上角分享按钮 , share_cake: 触发分享微信好友及分享朋友圈功能,
 * back: YC加油站列表参数信息
 * @param params.path 跳转的APP地址
 * @param params.url 分享的微信后链接地址
 * @param params.image 分享到微信的图片
 * @param params.title 分享到微信的title
 * @param params.des 分享到微信后的摘要
 * @param params.isShow isShowShare 下是否展示右上角分享开关 1 开启 0 关闭
 * @param params.posterText 悦淘瓜分活动朋友圈海报上特殊的文案
 * @param params.posterImage 悦淘瓜分活动海报背景
 * @param params.name 跳转H5页面必填参数
 * @param params.redirectType 跳转H5页面跳转类型
 * @param params.redirectUrl 跳转H5页面需要跳转的链接地址
 */
export function useAppMethods(params) {
  const pJsonStr = JSON.stringify(params)
  if (window.NativeMethod) {
    // 安卓原生
    window.NativeMethod.postMessage(pJsonStr)
  } else if (
    window.webkit &&
    window.webkit.messageHandlers &&
    window.webkit.messageHandlers.NativeMethod
  ) {
    // IOS原生方法
    window.webkit.messageHandlers.NativeMethod.postMessage(pJsonStr)
  } else if (window.ReactNativeWebView) {
    // 安卓RN
    window.ReactNativeWebView.postMessage(pJsonStr)
  }
}

/**
 * 打开App登录页面
 * 2021-07-27 16:55:01
 * @author SnowRock
 */
export function openAppLogin() {
  useAppMethods({
    eventName: 'login'
  })
}

/**
 * 是否展示App分享按钮
 * 2021-07-27 16:52:32
 * @author SnowRock
 * @param state 1 展示 0 不展示
 */
export function isShowAppShare(state) {
  useAppMethods({
    eventName: 'isShowShare',
    isShow: state
  })
}

/**
 * 获取悦淘App方法
 * @param params
 * @returns {Promise<{ eventName: String, result: {} }>}
 */
export function getAppInfo(params) {
  return new Promise((resolve, reject) => {
    if (!isYTApp()) {
      reject({ message: '非YTApp内部环境' })
      return
    }
    let appInfo = null
    if (window.NativeMethod) {
      // 安卓原生
      appInfo = window.NativeMethod.getAppInfo(JSON.stringify(params))
    } else if (window.ReactNativeWebView) {
      // 安卓RN
      appInfo = window.ReactNativeWebView.getAppInfo(JSON.stringify(params))
    } else if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.NativeMethod) {
      // IOS原生方法
      appInfo = prompt(JSON.stringify(params))
    }
    appInfo ? resolve(JSON.parse(appInfo)) : reject({ message: '获取信息失败' })
  })
}

/**
 * 从App内获取用户登录状态
 * 2021-07-26 18:23:52
 * @author SnowRock
 * @returns {Promise<{ "eventName": "loginState", "resultBean": { "loginState": "1" }}>}
 */
export function fetchAppLoginState() { return getAppInfo({ eventName: 'loginState' }) }

/**
 * 从App内获取用户定位地址
 * 2021-07-26 18:23:52
 * @author SnowRock
 * @returns {Promise<{ "eventName":  "location","resultBean": { "lat": "", "lon": "" }}>}
 */
export function fetchAppUserLocation() { return getAppInfo({ eventName: 'location' }) }

/**
 * 从App内获取用户Token
 * 2021-07-26 18:23:52
 * @author SnowRock
 * @returns {Promise<{ "eventName": "token", "resultBean": { "token": "" }}>}
 */
export function fetchAppUserToken() { return getAppInfo({ eventName: 'token' }) }

/**
 * 从App内获取用户Mid
 * 2021-07-26 18:23:52
 * @author SnowRock
 * @returns {Promise<{ "eventName": "mid"," resultBean": { "mid": "" }}>}
 */
export function fetchAppUserMid() { return getAppInfo({ eventName: 'mid' }) }

/**
 * 从App内获取用户信息
 * 2021-07-26 18:23:52
 * @author SnowRock
 * @returns {Promise<{ "eventName": "userInfo", "resultBean": { "memberState":"0", "nickname":"我是超级无敌奥特曼", "reCode":"9925295468" }}>}
 */
export function fetchAppUserInfo() { return getAppInfo({ eventName: 'userInfo' }) }
