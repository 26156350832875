/* eslint-disable no-undef */
/**
 * @description: 路由拦截
 *
 */

import router from './index'
import { isWeChatApplet, isYTApp } from '@/utils/common/validate'
import { fetchAppLoginState, fetchAppUserMid, fetchAppUserToken, openAppLogin } from '@/utils/app'

// 路由导航守卫
router.beforeEach(async(to, from, next) => {
  // 登录权限
  if (to.meta.requireAuth) { // 判断是否校验登录权限
    console.log('isYTApp()', isYTApp())
    console.log('1')
    if (!localStorage.getItem('uid')) { // 判断是否登录，根据实际业务处理
      // 判断如果在悦淘App内部，自动获取token及mid
      console.log('没有登录')
      if (isYTApp()) {
        try {
          const { resultBean: { loginState }} = await fetchAppLoginState()
          if (Number(loginState) === 1) {
            const { resultBean: { token }} = await fetchAppUserToken()
            const { resultBean: { mid }} = await fetchAppUserMid()
            localStorage.setItem('uid', mid)
            localStorage.setItem('token', token)
            next()
            return
          } else {
            // 如果用户未登录并且没有用户信息则自动登录重新获取信息
            openAppLogin()
            next()
            return
          }
        } catch (e) {
          alert('通过错误')
          console.log(e)
          next({
            path: '/login',
            query: {
              redirect: to.fullPath // 未登录，跳转到登录页，登录后跳转回当前页。
            }
          })
          return
        }
      } else if (isWeChatApplet()) {
        // 如果在微信小程序下如果未登录则自动跳转至登录页面， 判断目前处于哪个微信小程序下
        const { appletType, token, mid } = to.query
        if (token && mid) {
          localStorage.setItem('token', token)
          localStorage.setItem('uid', mid)
          next()
          return
        } else {
          // 本地生活进入登录页面
          if (appletType === 'LOCAL_APPLET') {
            wx.miniProgram.navigateTo({
              path: '/page/login/login'
            })
            next()
            return
          }
        }
      }
      next({
        path: '/login',
        query: {
          redirect: to.fullPath // 未登录，跳转到登录页，登录后跳转回当前页。
        }
      })
      return
    }
  }
  // 路由发生变化修改页面title
  if (to.meta.title) {
    document.title = to.meta.title
  } else {
    document.title = '悦淘'
  }
  next()
})

router.afterEach((to, from) => {
  if (!(from.path === '/' && from.name === null)) {
    setLocalRoute(to, from)
  }
  console.log('isYTApp()', isYTApp())
  console.log(window.navigator)
})

function setLocalRoute(to, from) {
  // 本地已访问页面路由,存5条
  const localRoute = window.myVue.localRoute = window.myVue.localRoute || []
  const from_index = localRoute.indexOf(from.path)
  const to_index = localRoute.indexOf(to.path)
  if (from_index < 0) {
    localRoute.unshift(from.path)
    to_index >= 0 && localRoute.splice(to_index, 1)
  }
  if (localRoute.length > 5) {
    localRoute.splice(0, 1)
  }
}
