// 截取window.location.href内所有参数方法 type:obj(输出所有参数对象)/str(输出所有参数集合串)
export function urlConversion(type, url) {
  const urls = url || window.location.href

  let oldHttp; let oldUrlArr = []
  if (urls) {
    if (urls.indexOf('?') !== -1) {
      oldUrlArr = urls.split('?')

      oldHttp = oldUrlArr[0]

      oldUrlArr.shift()
    }
  }

  if (urls.indexOf('?') !== -1) {
    const urlArr = urls.split('?')

    if (urlArr.length > -1) {
      const PArr = urlArr[1].split('&')
      if (PArr.length !== 0) {
        const CArr = []; const obj = {
          Http: oldHttp,
          value: {}

        }

        // PArr = [...PArr, ...oldUrlArr]

        const newSet = new Set(PArr)

        const newPArr = Array.from(newSet)

        newPArr.forEach(e => {
          CArr.push(e.split('='))
        })

        if (CArr.length !== 0) {
          CArr.forEach(el => {
            obj.value[el[0]] = el[1]
          })
        }

        if (localStorage.getItem('recode')) {
          obj.value.codeNumber = localStorage.getItem('recode')
        }
        if (!type || (type && type === 'str')) {
          const strArr = []

          for (const key in obj.value) {
            strArr.push(key + '=' + obj.value[key])
          }

          if (url) {
            return oldHttp + '?' + strArr.join('&')
          } else {
            return strArr.join('&')
          }
        } else if (type && type === 'obj') {
          return obj
        }
      }
    }
  } else {
    if (url) {
      return url
    } else {
      if (type === 'str') {
        return ''
      } else {
        return null
      }
    }
  }
}

// 判断当前操作系统
export function SystemVersion(isWeixin) {
  const u = navigator.userAgent
  const weixin = u.toLowerCase().match(/MicroMessenger/i) === 'micromessenger'
  const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1 // Android终端
  const isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) // ios终端
  if (isWeixin) {
    if (weixin) {
      return 'weixin'
    } else {
      return null
    }
  } else {
    if (isAndroid) {
      // 这个是安卓操作系统
      return 'isAndroid'
    }
    if (isIOS) {
      // 这个是ios操作系统
      return 'isIOS'
    }
  }
}

// 判断浏览器内核

export function getBrowserAgent(type) {
  var agent = navigator.userAgent.toLowerCase()
  const regStr_ie = /msie [\d.]+;/gi
  const regStr_ff = /firefox\/[\d.]+/gi
  const regStr_chrome = /chrome\/[\d.]+/gi
  const regStr_saf = /safari\/[\d.]+/gi
  const regStr_weixin = /MicroMessenger/i
  let arr = []
  // IE
  if (agent.indexOf('msie') > 0) {
    arr = agent.match(regStr_ie)
  }
  // firefox
  if (agent.indexOf('firefox') > 0) {
    arr = agent.match(regStr_ff)
  }
  // Chrome
  if (agent.indexOf('chrome') > 0) {
    arr = agent.match(regStr_chrome)
  }
  // 微信内核
  if (agent.indexOf('safari') > 0 && agent.indexOf('micromessenger') > 0 && agent.indexOf('chrome') < 0) {
    arr = agent.match(regStr_weixin)
  }
  // Safari
  if (agent.indexOf('safari') > 0 && agent.indexOf('micromessenger') < 0 && agent.indexOf('chrome') < 0) {
    arr = agent.match(regStr_saf)
  }

  if (arr.length !== 0) {
    if (arr[0] !== 'micromessenger') {
      if (type && type === 'isWeiXin') {
        return false
      } else {
        return {
          browser: arr[0].split('/')[0],
          browser_version: arr[0].split('/')[1]
        }
      }
    } else {
      if (type && type === 'isWeiXin') {
        return true
      } else {
        return {
          browser: arr[0],
          browser_version: ''
        }
      }
    }
  } else {
    if (type && type === 'isWeiXin') {
      return false
    } else {
      return null
    }
  }
}

// 判断是在webview里是ios、安卓还是RN

export function getClientInfo(type) {
  var ua = navigator.userAgent.toLowerCase()
  let inIos = false
  if (window.webkit !== undefined && window.webkit.messageHandlers !== undefined && window.webkit.messageHandlers.NativeMethod !== undefined) {
    inIos = true
  }

  if (!type) {
    if (ua.match(/MicroMessenger/i) === 'micromessenger' && !inIos) {
      return false
    } else {
      if (window.NativeMethod === undefined && !inIos && window.ReactNativeWebView === undefined) {
        // &&window.__wxjs_environment===undefined
        return false
      } else {
        return true
      }
    }
  } else {
    if (ua.match(/MicroMessenger/i) === 'micromessenger' && !inIos) {
      if (window.__wxjs_environment === undefined) {
        return 'weixin'
      } else {
        return 'MiniProgram'
      }
    } else {
      if (window.NativeMethod) {
        // 安卓原生
        return 'android'
      } else if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.NativeMethod) {
        // IOS原生方法
        return 'ios'
      } else if (window.ReactNativeWebView) {
        // 安卓RN
        return 'RN'
      } else if (window.__wxjs_environment) {
        // 这里判断是否是微信小程序
        return 'MiniProgram'
      } else {
        return 'H5'
      }
    }
  }
}

// 唤醒跳转APP
export function wakeApp(data) {
  const isWeixin = SystemVersion('isWeixin')
  const sysCode = SystemVersion()
  let redirectType = -1
  // const id = ''
  // const skuId = ''
  let str = ''
  console.log(data)
  if (data) {
    if (data.product_type) {
      switch (data.product_type) {
        case 1:
          redirectType = 3
          str = '?redirectType=3'
        // eslint-disable-next-line no-fallthrough
        case 2:
          redirectType = 104
          str = '?redirectType=104'
          break
        case 3:
          redirectType = 88
          str = '?redirectType=88'
          break
        case 4:
          redirectType = 95
          str = '?redirectType=95'
          break
        case 5:
          redirectType = 110
          str = '?redirectType=110'
          break
        case 6:
          redirectType = 94
          str = '?redirectType=94'
          break
        default:
          redirectType = data.product_type
          str = '?redirectType=' + data.product_type
      }
      // TODO 注意所有的redirectType 是否有用
      console.log('redirectType ->', redirectType)
      if (data) {
        for (const key in data) {
          if (key !== 'product_type' && data[key] && key !== 'codeNumber') {
            str += '&' + key + '=' + data[key]
          }
        }
      }

      if (urlConversion('obj') && urlConversion('obj').codeNumber) {
        str += '&codeNumber=' + urlConversion('obj').codeNumber
      }

      str += '&needLogin=1'
    }
  }

  if (isWeixin === 'weixin') {
    if (sysCode === 'isAndroid') {
      window.location.href = 'https://a.app.qq.com/o/simple.jsp?pkgname=com.newHuanQiuYueLv.www&from=singlemessage'
      // window.location.href = 'https://open.weixin.qq.com/cgi-bin/bizdetail?t=wxverify/detail_mp_account&lang=zh_CN&token=c7bb62e736f2eb167142adb5b297ff4625ca41a6&appid=wx05c589153c56bcfe'
    } else if (sysCode === 'isIOS') {
      window.location.href = 'https://apps.apple.com/cn/app/%E6%82%A6%E6%97%85%E4%BC%9A/id1460325408'
      // window.location.href = 'https://www.yuetao.vip/ytApp/openPage?redirectType=39&id=634'
    }
  } else {
    if (sysCode === 'isAndroid') {
      if (str) {
        window.location.href = 'yuetao://www.yuetao.vip' + str
      } else {
        window.location.href = 'yuetao://www.yuetao.vip'
      }

      setTimeout(function() {
        window.location.href = 'https://a.app.qq.com/o/simple.jsp?pkgname=com.newHuanQiuYueLv.www&from=singlemessage'
      }, 2000)
    } else if (sysCode === 'isIOS') {
      if (str) {
        window.location.href = 'yuelvhui://www.yuetao.vip' + str
      } else {
        window.location.href = 'yuelvhui://www.yuetao.vip'
      }

      setTimeout(function() {
        window.location.href = 'https://apps.apple.com/cn/app/%E6%82%A6%E6%97%85%E4%BC%9A/id1460325408'
      }, 2000)
    }
  }
}

// 倒计时
export function countTime(EndTime) {
  // eslint-disable-next-line no-redeclare
  var EndTime = EndTime// 结束时间
  var NowTime = new Date()// 当前时间
  var t = EndTime - ((NowTime.getTime() / 1000).toFixed(0) * 1)

  // 如果后台给的是毫秒 上面不用除以1000  下面的计算时间也都要除以1000 这里我去掉1000了
  var d = Math.floor(t / 60 / 60 / 24)// 天
  var h = Math.floor(t / 60 / 60 % 24)// 时
  var m = Math.floor(t / 60 % 60)// 分
  var s = Math.floor(t % 60)// 秒

  function tudo(ts) {
    return ts < 10 ? '0' + ts : ts
  }

  return {
    d: tudo(d), h: tudo(h), m: tudo(m), s: tudo(s)
  }
}

// 时间差倒计时
export function timeDifference(startTime, EndTime) {
  // var EndTime = EndTime//结束时间
  // var NowTime = new Date();//当前时间
  var t = startTime - EndTime

  // 如果后台给的是毫秒 上面不用除以1000  下面的计算时间也都要除以1000 这里我去掉1000了
  var d = Math.floor(t / 60 / 60 / 24)// 天
  var h = Math.floor(t / 60 / 60 % 24)// 时
  var m = Math.floor(t / 60 % 60)// 分
  var s = Math.floor(t % 60)// 秒

  function tudo(ts) {
    return ts < 10 ? '0' + ts : ts
  }

  return {
    d: tudo(d), h: tudo(h), m: tudo(m), s: tudo(s)
  }
}

// 保留账号后四位
export function cutString(str, len) {
  let lastStr = ''
  len = len || 4

  // var disLength = disName.length;
  var shortName = str.substring(str.length - len, str.length)

  if (len) {
    for (let i = 0; i < (str.length - len * 1); i++) {
      lastStr += '*'
    }
  }
  return lastStr + shortName
}

// 保留身份证前6后四位
export function IDcutString(str) {
  if (str != null && str !== undefined) {
    let star = '' // 存放名字中间的*
    // 名字是两位的就取姓名首位+*
    if (str.length <= 2) {
      return str.substring(0, 1) + '*'
    } else {
      for (var i = 0; i < str.length - 10; i++) {
        star = star + '*'
      }
      return str.substring(0, 6) + star + str.substring(str.length - 4, str.length)
    }
  }
}
