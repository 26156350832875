
import request from '@/services/request.js'

/**
 * 获取手机验证码
 * */
export function sendNewByWeChat(data) {
  return request({
    url: '/app/auth/sendNewByWeChat',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}

/**
 * 获取邮箱验证码
 * */
export function sendSmsCode(data) {
  return request({
    url: '/app/auth/sendSmsCode',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}

/**
 * 获取语音验证码文案
 * */
export function voiceSendTips() {
  return request({
    url: '/app/auth/voiceSendTips',
    method: 'post',
    prefix: 'yuetaoGroup'
  })
}

/**
 * 获取邮箱验证码
 * */
export function sendVoiceVerifyCode(data) {
  return request({
    url: '/app/auth/sendVoiceVerifyCode',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}

/**
 * 判断是否是老用户
 * */
export function isCheckUserRegister(data) {
  return request({
    url: '/app/isCheckUserRegister',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}

/**
 * 登录
 * */
export function verifyAndRegister(data) {
  return request({
    url: '/app/auth/verifyAndRegister',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}
/**
 * 绑定手机注册
 * */
export function Registers(data) {
  return request({
    url: '/app/member/register',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}

/**
 * 绑定邮箱注册
 * */
export function smsRegister(data) {
  return request({
    url: '/app/auth/smsRegister',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}

/**
 * 邮箱绑定手机
 * */
export function bindMobile(data) {
  return request({
    url: '/app/auth/bindMobile',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}

/**
 * 退出
 * */
export function loginOut(data) {
  return request({
    url: '/app/auth/loginOut',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}

/**
 * 获取邀请码用户信息
 * */
// export function getParentInfo(data) {
// 	return request({
// 		url: '/app/member/v4/getParentInfo',
// 		method: 'get',
// 		data,
// 		prefix:'yuetaoGroup'
// 	})
// }

//

// 邀请注册后的跳转
export function getH5RegisterUrl(code) {
  return request({
    url: 'mall/V4/getH5RegisterUrl',
    method: 'post',
    prefix: 'yuetaoGroup'
  })
}

// 获取邀请人信息
export function getshowTip(code) {
  return request({
    url: 'app/member/v4/getParentInfo/' + code,
    method: 'get',
    prefix: 'yuetaoGroup'
  })
}

// 绑定邀请人
export function memberRegister(data) {
  return request({
    url: '/app/member/register',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}

/**
 * 微信授权第一步
 * */
export function getWxAppId(data) {
  return request({
    url: '/hf/getWxAppId',
    method: 'post',
    data,
    prefix: 'yuelvhui'
  })
}

/**
 * 微信授权第二步
 * */
export function getCodeByOpenid(data) {
  return request({
    url: '/app/getCodeByOpenid',
    method: 'post',
    data,
    prefix: 'yuelvhui',
    headers: {
      hideError: true
    }
  })
}

export function getWapTokenByUnionid(data) {
  return request({
    url: '/app/auth/getWapTokenByUnionid',
    method: 'post',
    data,
    prefix: 'yuelvhui',
    headers: {
      hideError: true
    }
  })
}

/**
 * 获取用户优惠券
 * */

/**
 * 获取用户收货地址
 * */
export function addressH5List(data) {
  return request({
    url: '/app/mall/address/h5List',
    method: 'post',
    data,
    prefix: 'yuetaoGroup',
    headers: {
      hideLoading: true
    }

  })
}

/**
 *添加用户收货地址
 * */
export function addressH5Create(data) {
  return request({
    url: '/app/mall/address/h5Create',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}

// --------------------------------------------------------------------
// 个人中心页
// 个人中心用户详情
export function MemberInfo(data) {
  return request({
    url: '/app/h5/MemberInfo',
    method: 'post',
    data,
    prefix: 'yuelvhui'
  })
}
// 个人中心收益
export function revenueStatistics(data) {
  return request({
    url: '/app/h5/revenueStatistics',
    method: 'post',
    data,
    prefix: 'yuelvhui'
  })
}

// 商品排行前10
export function getSalesRankingList(data) {
  return request({
    url: '/stall/getSalesRankingList',
    method: 'post',
    data,
    prefix: 'shopyuelvhui'
  })
}
// 优惠券查询
export function couponGroupMember(data) {
  return request({
    url: '/app/h5/couponList',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}

// 个人设置页
// 修改姓名
export function truename(data) {
  return request({
    url: '/app/member/basic/update/truename',
    method: 'post',
    data,
    prefix: 'yuelvhui'
  })
}
// 修改昵称
export function nickname(data) {
  return request({
    url: '/app/member/basic/update/nickname',
    method: 'post',
    data,
    prefix: 'yuelvhui'
  })
}
// 修改性别
export function gender(data) {
  return request({
    url: '/app/member/basic/update/gender',
    method: 'post',
    data,
    prefix: 'yuelvhui'
  })
}

// 下单页面
// 普通商品结算
export function getProuctBlanceInfoV4(data) {
  return request({
    url: '/mall/getProuctBlanceInfoV4',
    method: 'post',
    data,
    prefix: 'shopyuelvhui'
  })
}
// 新悦豆规则
export function myPoint(data) {
  return request({
    url: '/mall/myPoint',
    method: 'post',
    data,
    prefix: 'shopyuelvhui'
  })
}

// 新悦豆规则详情
export function getProuctByPoint(data) {
  return request({
    url: '/mall/getProuctByPoint',
    method: 'post',
    data,
    prefix: 'shopyuelvhui'
  })
}
// 普通商品下单
export function createOrderByProductV4(data) {
  return request({
    url: '/mall/createOrderByProductV6',
    method: 'post',
    data,
    prefix: 'shopyuelvhui'
  })
}
// 获取直播间
export function getSignUpMemberInfo(data) {
  return request({
    url: '/stall/wx/signUp/getSignUpMemberInfo',
    method: 'post',
    data,
    prefix: 'shopyuelvhui'
  })
}

// 秒杀商品结算
export function flashBalanceNew(data) {
  return request({
    url: '/mall/flashBalanceNew',
    method: 'post',
    data,
    prefix: 'shopyuelvhui'
  })
}

// 秒杀商品下单
export function createFlashOrderNew(data) {
  return request({
    url: 'mall/createFlashOrderNewNowV2',
    method: 'post',
    data,
    prefix: 'shopyuelvhui'
  })
}

// 购物车商品结算
export function getCartBlanceInfoV4(data) {
  return request({
    url: '/mall/cart/getCartBlanceInfoV5',
    method: 'post',
    data,
    prefix: 'shopyuelvhui'
  })
}

// 购物车商品下单
export function createOrderByCartV4(data) {
  return request({
    url: '/mall/createOrderByCartV7',
    method: 'post',
    data,
    prefix: 'shopyuelvhui'
  })
}

// 支付
export function newMallPayV4(data) {
  return request({
    url: '/app/newMallPayV4',
    method: 'post',
    data,
    prefix: 'yuelvhui'
  })
}

// 获取版本号
export function getPayVersion(data) {
  data.payType = 1
  return request({
    url: '/app/member/order/getPayVersion',
    method: 'post',
    data,
    prefix: 'yuelvhui'
  })
}

// ---------------------------
// 获取商品规格
export function getProductBuyDatailV4(data) {
  return request({
    url: '/mall/getProductBuyDatailV4',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}

// 获取商品规格
export function getProductSkuDatail(data) {
  return request({
    url: '/product/skuInfo',
    method: 'post',
    data,
    prefix: 'yuetaoGroup',
    headers: {
      hideLoading: true
    }
  })
}

// 凑单详情
export function getActivityCategoryInfo(data) {
  return request({
    url: '/mall/V3/getActivityCategoryInfo',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}
// 凑单列表
export function newActivityList(data) {
  return request({
    url: '/mall/V3/newActivityList',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}

// 购物车列表
export function ShoplistV4(data) {
  return request({
    url: '/mall/cart/listV5',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}

// 加入购物车列表
export function ShopcreateV4(data) {
  return request({
    url: '/mall/cart/createV5',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}

// 领券接口
export function getCoupon(data) {
  return request({
    url: 'mall/sendCoupon',
    method: 'post',
    data,
    prefix: 'shopyuelvhui'
  })
}

// 地址选择省
export function getProvince(data) {
  return request({
    url: '/app/mall/jd/getProvince',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}

// 地址选择市
export function getCity(data) {
  return request({
    url: '/app/mall/jd/getCity',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}

// 地址选择区
export function getArea(data) {
  return request({
    url: '/app/mall/jd/getArea',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}

export function ajaxAutoLogin(data) {
  return request({
    url: '/app/auth/getWapToken',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}

export function getMemberInfo(data) {
  return request({
    url: '/wechat/member/info',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}

// 注册后埋点(获取用户信息)
export function reportAnalytics(data) {
  return request({
    url: '/wechat/report/analytics',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}

// get用户会员类型
export function getEquityType(data) {
  return request({
    url: '/app/getMemberInfo',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}

// 我的
export function getusernew() {
  return request({
    url: '/app/member/v3/newGet',
    method: 'get',
    prefix: 'yuetaoGroup'
  })
}

// 我的钱包
export function newGetSales(data) {
  return request({
    url: '/app/member/newGetSales',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}
// 提现记录
export function cash() {
  return request({
    url: '/app/member/bonus/cash',
    method: 'get',
    prefix: 'yuetaoGroup'
  })
}

// 我的钱包/账户余额
export function getBalanceMoneyData() {
  return request({
    url: '/app/member/balance/getBalanceMoneyData',
    method: 'get',
    prefix: 'yuetaoGroup'
  })
}

// 充值协议
export function getBalanceRule(data) {
  return request({
    url: '/app/getBalanceRule',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}

// 采购结算
export function purchase(data) {
  return request({
    url: '/app/member/bonus/order/listv1',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}

// 销售结算
export function saleMoney(data) {
  return request({
    url: '/app/member/saleMoney',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}

// 佣金余额(提现绑定信息)
export function cashnewGet(data) {
  return request({
    url: '/app/member/cash/newGet',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}

// 佣金余额/提现
export function cashnewApply(data) {
  return request({
    url: '/app/member/cash/newApply',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}

// 我的邀请
export function invitemyNewList() {
  return request({
    url: '/app/member/invite/myNewList/1',
    method: 'get',
    prefix: 'yuetaoGroup'
  })
}

// 间推
export function invitechildList(i, p) {
  return request({
    url: '/app/member/invite/childList' + '/' + i + '/' + p,
    method: 'get',
    prefix: 'yuetaoGroup'
  })
}

// 悦豆记录列表
export function integralList(t, p) {
  return request({
    url: '/app/member/integralList/' + p + '/' + t,
    method: 'get',
    prefix: 'yuetaoGroup'
  })
}

// 我的二维码
export function qrcodeV4() {
  return request({
    url: '/app/member/qrcodeV4',
    method: 'post',
    prefix: 'yuetaoGroup'
  })
}

// 游览记录
export function productBrowsingList(data) {
  return request({
    url: '/mall/productBrowsingList',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}

// 酒店收藏
export function hotelcollectLis(data) {
  return request({
    url: '/app/hotel/collectLis',
    method: 'get',
    params: data,
    prefix: 'yuetaoGroup'
  })
}

// 会员信息
export function memberinfo() {
  return request({
    url: '/app/member/info',
    method: 'post',
    prefix: 'yuetaoGroup'
  })
}

// 会员信息/修改姓名
export function upTruename(data) {
  return request({
    url: '/app/member/basic/update/truename',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}

// 会员信息/修改昵称
export function upnickname(data) {
  return request({
    url: '/app/member/basic/update/nickname',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}

// 会员信息/修改性别
export function upGender(data) {
  return request({
    url: '/app/member/basic/update/gender',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}

// 完善信息/证件号码
export function certifiId(data) {
  return request({
    url: '/app/member/basic/update/certifiId',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}

// 修改登录密码
export function updatePassword(data) {
  return request({
    url: '/app/member/updatePassword',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}

// 获取验证码
export function authnewSend(data) {
  return request({
    url: '/app/auth/send',
    method: 'post',
    headers: {
      Authorization: 'Sys 2001.1528204771000.ee8647b6f5b91531aa26b1b2acb9b458'
    },
    data,
    prefix: 'yuetaoGroup'
  })
}

// checkIdentifyTimes
export function checkIdentifyTimes(data) {
  return request({
    url: '/app/member/checkIdentifyTimes',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}

// 实名认证
export function getRealNameInfo() {
  return request({
    url: '/app/member/getRealNameInfo',
    method: 'get',
    prefix: 'yuetaoGroup'
  })
}
// 实名认证绑定
export function identityAuthentication(data) {
  return request({
    url: '/app/member/identityAuthentication',
    method: 'post',
    headers: {
      hideError: true
    },
    data,
    prefix: 'yuetaoGroup'
  })
}

// 重新认证实名认证绑定
export function againRealName(data) {
  return request({
    url: '/app/member/againRealName',
    method: 'post',
    headers: {
      hideError: true
    },
    data,
    prefix: 'yuetaoGroup'
  })
}

// 绑定银行卡
export function bindBankCard(data) {
  return request({
    url: '/app/member/bindBankCard',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}

// 图片上传接口

export function getUploadImg(data) {
  return request({
    url: '/common/uploadBase64',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}

// 佣金转入
export function overToBalance(data) {
  return request({
    url: '/app/member/balance/overToBalance',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}

// 我的钱包/账户余额(修改密码)
export function setBalancePwd(data) {
  return request({
    url: '/app/member/balance/setBalancePwd',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}

// 我的订单icon
export function myOrder() {
  return request({
    url: '/app/member/myOrder',
    method: 'get',
    prefix: 'yuetaoGroup'
  })
}

// 我的订单/商城订单(直播订单)
export function liveOrderList(data) {
  return request({
    url: '/app/member/order/liveOrderList',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}

export function flashOrderList(data) {
  return request({
    url: '/mall/member/order/flashOrderList',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}

//
export function flashOrderByType(data) {
  return request({
    url: '/mall/member/order/flashOrderByType',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}

// 我的订单/商城订单(拼团订单)
export function MyAssemble(data) {
  return request({
    url: '/hd/MyAssemble',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}

// 砍价订单列表
export function myBargainLists(data) {
  return request({
    url: '/hd/myBargainLists',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}

// 积分商城订单
export function integralOrderList(data) {
  return request({
    url: '/app/member/integralOrderList',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}

// 查看全部订单
export function classList(data) {
  return request({
    url: '/app/member/myOrder/classList',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}

// 查看全部订单
export function classListH5(data) {
  return request({
    url: '/app/h5/myOrder/classList',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}

// 站外订单
export function orderList(data) {
  return request({
    url: '/app/outside/orderList',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}

// 站外订单/订单说明
export function newBonusInstruction(data) {
  return request({
    url: '/app/newBonusInstruction',
    method: 'get',
    prefix: 'yuetaoGroup'
  })
}

// 订单详情
export function flashOrderDetail(data) {
  return request({
    url: '/app/member/myOrder/detailV2',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}

// 订单详情
export function flashOrderDetailH5(data) {
  return request({
    url: '/app/h5/myOrder/detailV2',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}

// 股票账户
export function addAccount(data) {
  return request({
    url: '/app/member/myOrder/addAccount',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}

// 股票账户
export function selectAccount(data) {
  return request({
    url: '/app/member/myOrder/selectAccount',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}

// 曹操出行订单列表
export function Travellist(data) {
  return request({
    url: '/travel/v1/order/list',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}

// 商城订单 查看物流
export function goodsExpress(data) {
  return request({
    url: '/app/member/goodsExpress',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}

// 绑定邮箱
export function updateemail(data) {
  return request({
    url: '/app/member/basic/update/email',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}

// 商城订单 删除订单
export function mallNewOrderDelete(data) {
  return request({
    url: '/app/member/mallNewOrderDelete',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}

// 商城订单 取消订单
export function cancelOrders(data) {
  return request({
    url: '/app/member/myOrder/cancelOrder',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}

// 订单评论
export function addComment(data) {
  return request({
    url: '/mall/addComment',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}

// 订单详情 确认收货
export function confirmReceipt(data) {
  return request({
    url: '/app/member/confirmReceipt',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}

// 好大夫
export function orders(data) {
  return request({
    url: '/mall/doctor/orders',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}

// 拼团/团长免单开团详情
// export function getOrdersnTeamInfo (data) {
//     return request({
//         url: '/hd/getOrdersnTeamInfo',
//         method: 'post',
//         data,
//         prefix: 'yuetaoGroup'
//     })
// }

// 拼团/开团详情推荐列表
export function assemble(data) {
  return request({
    url: '/hd/assemble',
    method: 'get',
    params: data,
    prefix: 'yuetaoGroup'
  })
}

// 拼团订单详情
export function hdorderDetail(data) {
  return request({
    url: '/hd/orderDetail',
    method: 'post',
    data,
    prefix: 'yuetaoGroup'
  })
}

