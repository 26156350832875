/**
 * @Author: nowThen
 * @Date: 2019-08-22 14:30:51
 */

import Vue from 'vue'
import Vuex from 'vuex'
import router from '../routers/index'
import getters from './getters'
import { getShopCarList } from '@/services/goods.js'
import {
  urlConversion,
  getClientInfo
} from '@/utils/public.js'
import { wxConfigDefault, wxConfigInit, wxConfigWakeApp } from '@/utils/wx-api'
import { routerRN } from '@/utils/app'
import { getUrlParams, setLocal } from '@/utils/common/utils'
import { isYTApp } from '../utils/common/validate'
Vue.use(Vuex)

const modulesFiles = require.context('./modules', true, /\.js$/)
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  const value = modulesFiles(modulePath)
  modules[moduleName] = value.default
  return modules
}, {})

const store = new Vuex.Store({
  modules,
  getters,
  state: {
    ScrollDate: null,
    showLoading: false,
    showSku: false,
    isSkipLinks: true,
    codeNumber: '', // 分享码
    room_id: '', // 关系id
    live_id: '', // 直播间id
    live_people_id: '', // 主播id
    robot_send: '',
    addressInfo: {}, // 详情页面地址信息中转处理
    addressReady: false,
    skuIdx: 0, // 规格信息索引
    skuInfo: null, // 设置当前选择的SKU信息
    skuStatus: null, // 当前商品弹框入口参数处理 1. 换购 redemption
    interceptUrl: '',
    shareData: null,
    isShow: false, // 拼团
    isTabVant: 0,
    cartCount: 0,
    isbtnTabVal: '99 || all',
    // ////////////////// 开发票 start ////////////////////
    titleType: '', // 1
    number: ' ',
    title: ' ',
    invoiceType: '',
    nubId: '',
    email: ' '
    // //////////////////开发票 end ////////////////////
  },
  mutations: {
    // //////////////////开发票 start ////////////////////
    isTitleType: (state, status) => {
      state.titleType = status
    },
    isNumber: (state, status) => {
      state.number = status
    },
    isTitle: (state, status) => {
      state.title = status
    },
    isInvoiceType: (state, status) => {
      state.invoiceType = status
    },
    isId: (state, status) => {
      state.nubId = status
    },
    isEmail: (state, status) => {
      state.email = status
    },

    // ////////////////// 开发票 end////////////////////
    btnIsShow: (state, status) => {
      state.isShow = status
    },
    btnTabVant: (state, status) => {
      state.isTabVant = status
    },
    btnTabVal: (state, status) => {
      state.isbtnTabVal = status
    },
    shareDataChange: (state, status) => {
      state.shareData = status
    },
    changeIsSkipLinks: (state, status) => {
      state.isSkipLinks = status
    },
    changeShowLoading: (state, status) => {
      state.showLoading = status
    },
    changeShowSku: (state, status) => {
      state.showSku = status
    },
    // 商品弹框入口状态： 1. 换购 redemption
    changeShowSkuStatus: (state, status) => {
      state.skuStatus = status
    },
    changeCodeNumber: (state, status) => {
      state.codeNumber = status
    },
    changeRoomid: (state, status) => {
      state.room_id = status
    },
    changeRobotSend: (state, status) => {
      state.robot_send = status
    },
    changeLiveid: (state, status) => {
      state.live_id = status
    },
    changeLivePeopleid: (state, status) => {
      state.live_people_id = status
    },
    handleAddressInfo: (state, status) => {
      setLocal('addressItem', JSON.stringify(status))
      state.addressInfo = status
    },
    changeAddressReady: (state, status) => {
      state.addressReady = status
    },
    changeSkuIdx: (state, status) => {
      state.skuIdx = status
    },
    changeSkuInfo: (state, status) => {
      state.skuInfo = status
    },
    changeInterceptUrl: (state, status) => {
      // 缓存登录拦截的地址
      state.interceptUrl = status
      window.sessionStorage.setItem('detailUrl', status)
    },
    SET_CARTCOINT: (state, status) => {
      state.cartCount = status
    },
    ScrollDateChange: (state, status) => {
      state.ScrollDate = status
    }
  },
  actions: {
    // 设置全局购物车数量
    getCartCount({ commit, state }, data) {
      let uid = ''
      uid = localStorage.getItem('uid')
      const body = {}
      if (uid) {
        body.uid = uid
        //   getShopCarList  newList
        getShopCarList(body).then((res) => {
          if (Number(res.code) === 200) {
            let num = 0
            if (res.data && res.data.length !== 0) {
              res.data.forEach((e) => {
                if (e.data && e.data.length !== 0) {
                  num += e.data.length
                }
              })
              commit('SET_CARTCOINT', num)
            } else {
              commit('SET_CARTCOINT', num)
            }
          } else {
            commit('SET_CARTCOINT', 0)
          }
        })
      } else {
        commit('SET_CARTCOINT', 0)
      }

      // return new Promise((resolve, reject) => {

      // })
    },
    // 登录后调整到当前页面逻辑
    loginIntercept({ commit, state }, obj) {
      // 登录逻辑
      if (!window.localStorage.getItem('uid')) {
        if (obj.codeNumber) {
          // router.replace('/login?codeNumber=' + obj.codeNumber + '&intercept=1')
          router.replace(
            '/login?backUrl=' +
            encodeURIComponent(
              encodeURIComponent(
                window.location.href + '&codeNumber=' + obj.codeNumber
              )
            )
          )
        } else {
          router.replace(
            '/login?backUrl=' +
            encodeURIComponent(encodeURIComponent(window.location.href))
          )
        }
      }
    },
    // 微信唤醒APP
    wxConfigWakeApp({ commit, state }, data) {
      wxConfigWakeApp()
    },

    // 微信分享配置
    wxConfigInit({ commit, state }, data) {
      wxConfigInit(data)
    },
    // 微信分享默认配置
    wxConfigDefault({ commit, state }, data) {
      wxConfigDefault()
    },
    // RN路由交互
    RNrouter({ commit, state }, data) {
      // _Router = {
      //     跳转RN优惠券列表页
      //     path: "router://cardVoucher", //优惠券列表
      //     ------------------------------------
      //     跳转到RN购物车
      //     path: "router://shoppingCart", //购物车
      //     -------------
      //     调取RN登录功能
      //     eventName: "login",
      //     --------------------
      //     RN页面内跳转H5页面
      //     name: "页面名称",
      //     redirectType: 111,//固定111
      //     redirectUrl: url,//需要跳转的链接地址
      //     ----------------------
      //     H5向RN发送分享H5端页面的分享内容和事件
      //     eventName: "share",
      //     url: url,//分享的微信后链接地址
      //     image: image,//分享到微信的图片
      //     title: title,//分享到微信的title
      //     des: desc,//分享到微信后的摘要
      //     --------------------------------
      //     跳转RN的商品详情
      //     redirectType: 3,//直营或者CPS
      //     id: item.goodId,//商品ID
      //     skuId: item.skuId,//SKUid
      //     RN商品详情跳转redirectType
      //     H5: 1.自营详情 2.秒杀详情 3.京东详情 4.拼多多详情 5.淘宝详情 6.唯品会详情 7.苏宁详情
      //     RN: 3.自营详情            88.京东详情 95.拼多多详情 110.淘宝详情 94.唯品会详情

      //   };
      routerRN(data)
    },

    godetail({ dispatch }, data) {
      let obj = urlConversion('obj') ? urlConversion('obj').value : {}

      if (!obj) {
        obj = {}
      }

      if (obj.env && obj.env === '98') {
        obj.goodsId = data.goodId || data.productId
        obj.skuid = data.productSkuId
        const strArr = []
        for (const key in obj) {
          strArr.push(key + '=' + obj[key])
        }
        const wxUrl = '/page/shopDetails/shopDetails?' + strArr.join('&')

        // eslint-disable-next-line no-undef
        wx.miniProgram.navigateTo({
          url: wxUrl
        })
      } else {
        const _Router = {
          redirectType: 3,
          id: data.goodId,
          skuId: data.skuId
        }
        if (data.activityId) {
          _Router.activityId = data.activityId
        }

        // IOS原生方法
        console.log(isYTApp())
        if (getClientInfo()) {
          const _Router = {
            redirectType: 3,
            id: data.goodId,
            skuId: data.skuId
          }
          if (data.activityId) {
            _Router.activityId = data.activityId
          }
          // C店商品跳转 - app
          if (data.cshopId) {
            _Router.cshopId = data.cshopId
          }

          switch (data.product_type) {
            case 1:
              _Router.redirectType = 3
              break
            case 3:
              _Router.redirectType = 88
              break
            case 4:
              _Router.redirectType = 95
              break
            case 5:
              _Router.redirectType = 110
              break
            case 6:
              _Router.redirectType = 94
              break
          }
          dispatch('RNrouter', _Router)
        } else {
          // product_type===1 是直营商品
          if (data.product_type === 1 || data.product_type === 7) {
            obj.goodsId = data.goodId || data.productId
            obj.skuid = data.skuId
            obj.activityId = data.activityId

            if (data.product_type === 7) {
              obj.status = 7
              obj.id = data.id
            }
            if (data.room_id) {
              obj.room_id = data.room_id
            }
            if (data.robot_send) {
              obj.robot_send = data.robot_send
            }

            router.push({
              path: '/goodsDetailZY',
              query: obj
            })
          } else if (data.product_type === 2) {
            obj.status = data.product_type
            obj.goodsId = data.goodId || data.productId
            obj.skuid = data.skuId
            obj.activityId = data.activityId
            router.push({
              path: '/goodsDetailMS',
              query: obj
            })
          } else if (data.product_type === 9) {
            obj.status = data.product_type
            obj.goodsId = data.goodId || data.productId
            obj.skuid = data.skuId
            obj.activityId = data.activityId
            router.push({
              path: '/goodsDetailXSBJ',
              query: obj
            })
          } else if (data.product_type === 39) {
            console.log('H5跳活动页面')
          } else {
            // product_type!==1 是CPS商品
            obj.goodsId = data.goodId || data.productId
            // obj.status = 3;
            obj.status = data.product_type
            if (data.linktype) {
              obj.linktype = data.linktype
            }
            if (data.id) {
              obj.id = data.id
            }
            if (data.eliteType) {
              obj.eliteType = data.eliteType
            }

            router.push({
              path: '/goodsDetailCPS',
              query: obj
            })
          }
        }
      }
    }
  }
})
// 存储关系
const {
  codeNumber = null, room_id = null, robot_send = null, liveid = null, live_people_id = null
} = getUrlParams() || {}

if (codeNumber) {
  store.commit('changeCodeNumber', codeNumber)
}

if (room_id) {
  store.commit('changeRoomid', room_id)
}

if (robot_send) {
  store.commit('changeRobotSend', robot_send)
}

if (liveid) {
  store.commit('changeLiveid', liveid)
}

if (live_people_id) {
  store.commit('changeLivePeopleid', live_people_id)
}

export default store
