/**
 * 验证方法集合
 */

/**
 * 验证是否在悦淘App中
 * 2021-06-02 18:13:51
 * @author SnowRock
 * @returns {boolean}
 */
export function isYTApp() {
  return isInNavigator('live_source_yt')
}

/**
 * 是否是支付宝内核
 * @returns {boolean}
 * @example inAlipay() false
 */
export function inAlipay() {
  return isInNavigator('alipayclient')
}

/**
 * 验证是否在微信环境中
 * 2021-06-02 18:13:51
 * @author SnowRock
 * @returns {boolean}
 */
export function isWeChat() {
  return isInNavigator('micromessenger')
}

/**
 * 验证是否在微信小程序环境中
 * 2021-06-03 14:01:50
 * @author SnowRock
 * @returns {boolean}
 */
export function isWeChatApplet() {
  return window.__wxjs_environment === 'miniprogram'
}

/**
 * 判断浏览器的user-agent信息是否包含字符
 * @param vg 字符
 * @returns {boolean}
 */
export function isInNavigator(vg) {
  if (typeof navigator === 'undefined') {
    return false
  }
  if (typeof vg === 'undefined') {
    return false
  }
  var agent = navigator.userAgent.toLowerCase()
  return agent.indexOf(vg.toLowerCase()) > -1
}

/**
 * 验证是否为一个有效日期格式
 * 2021-06-02 14:00:24
 * @author SnowRock
 * @param val 字符串
 * @returns {boolean} 是否有效
 */
export function isDateFormat(val) {
  if (val === '' || val === undefined || val == null) {
    return false
  }
  const reg = /^(\d{4})([-\/])(\d{1,2})\2(\d{1,2})$/
  if (!reg.test(val)) {
    return false
  }
  const date = new Date(val)
  return !(date == null || date === 'Invalid Date')
}

/**
 * 是否为有效的手机号
 * 新版规则 除了 12 以外的基本上都可以
 * @param {string} val
 * @returns {boolean}
 * @example isMobile('15898745678');  => true
 */
export function isMobile(val) {
  var reg = /^[1][3456789]\d{9}$/

  return reg.test(val)
}

/**
 * 是否为有效的车牌号码
 *
 * 1.常规车牌号：仅允许以汉字开头，后面可录入六个字符，由大写英文字母和阿拉伯数字组成。如：粤B12345；<br>
 * 2.武警车牌：允许前两位为大写英文字母，后面可录入七个字符，由大写英文字母和阿拉伯数字组成，其中第三位可录汉字也可录大写英文字母及阿拉伯数字，如：WJ01警0081、WJ0112345。<br>
 * 3.最后一个为汉字的车牌：允许以汉字开头，后面可录入六个字符，前五位字符，由大写英文字母和阿拉伯数字组成，而最后一个字符为汉字，汉字包括“挂”、“学”、“警”、“军”、“港”、“澳”。<br>如：粤Z1234港。
 * 4.新军车牌：以两位为大写英文字母开头，后面以5位阿拉伯数字组成。如：BA12345。<br>
 * 5.黑龙江车牌存在08或38开头的情况。<br>
 *
 * @param {string} val
 * @returns {boolean}
 * @example
 *
 * isLicenseNo('浙A12345');
 * // => true
 */
export function isLicenseNo(val) {
  var reg = /(^[\u4E00-\u9FA5]{1}[A-Z0-9]{6}$)|(^[A-Z]{2}[A-Z0-9]{2}[A-Z0-9\u4E00-\u9FA5]{1}[A-Z0-9]{4}$)|(^[\u4E00-\u9FA5]{1}[A-Z0-9]{5}[挂学警军港澳]{1}$)|(^[A-Z]{2}[0-9]{5}$)|(^(08|38){1}[A-Z0-9]{4}[A-Z0-9挂学警军港澳]{1}$)/

  return reg.test(val)
}

/**
 * 验证字符中只存在字母
 * @param {string} val
 * @returns {boolean}
 * @example isLetters('1234') => false
 */
export function isLetters(val) {
  var reg = /^[a-z]+$/i

  return reg.test(val)
}

/**
 * 验证传入的数字为闰年
 * @param {number} year 年份
 * @returns {boolean}
 * @example isLeapYear(2000); => true
 */
export function isLeapYear(year) {
  if (typeof year !== 'number') {
    throw new Error('val必须为number类型')
  }

  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0
}

/**
 * 验证传入的字符是否为数字字符
 * @param {string} val
 * @returns {boolean}
 * @example isDigit('abc'); => false
 */
export function isDigit(val) {
  var reg = /^-?\d+\.?\d*$/
  return reg.test(val)
}

/**
 * 验证是否为有效的身份证号
 *
 * @param {string} val
 * @returns {boolean}
 * @example isCardId('411423198807127834') => true
 */
export function isCardId(val) {
  var reg = /^\d{6}(19|2\d)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|X|x)?$/
  // var reg = /^ [1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]?$/;

  return reg.test(val)
}

/**
 * 验证字符串是否为普通的url
 * <br> http|https|ftp
 * <br> 2021-06-02 11:11:16
 * @author SnowRock
 * @param str {String} 字符串
 * @returns {boolean}
 */
export function isUrl(str) {
  var v = new RegExp('^(?!mailto:)(?:(?:http|https|ftp)://|//)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$', 'i')
  return v.test(str)
}

/**
 * 是否为有效的url<br>
 *
 * 支持类型:<br>
 * http(s)://(username:password@)(www.)domain.(com/co.uk)(/...)<br>
 * (s)ftp://(username:password@)domain.com/...<br>
 * git://(username:password@)domain.com/...<br>
 * irc(6/s)://host:port/... //<br>
 * afp over TCP/IP: afp://[<user>@]<host>[:<port>][/[<path>]]<br>
 * telnet://<user>:<password>@<host>[:<port>/]<br>
 * smb://[<user>@]<host>[:<port>][/[<path>]][?<param1>=<value1>[;<param2>=<value2>]]<br>
 *
 * @param {string} url 传入的字符串
 * @returns {boolean}
 * @example isValidURI('https://github.com/lodash') => true
 */
export function isValidURI(url) {
  var protocols = '((https?|s?ftp|irc[6s]?|git|afp|telnet|smb):\\/\\/)?'
  var userInfo = '([a-z0-9]\\w*(\\:[\\S]+)?\\@)?'
  var domain = '([a-z0-9]([\\w]*[a-z0-9])*\\.)?[a-z0-9]\\w*\\.[a-z]{2,}(\\.[a-z]{2,})?'
  var port = '(:\\d{1,5})?'
  var ip = '\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}'
  var address = '(\\/\\S*)?'
  var domainType = [protocols, userInfo, domain, port, address]
  var ipType = [protocols, userInfo, ip, port, address]

  var verify = function(type) {
    return new RegExp('^' + type.join('') + '$', 'i').test(url)
  }

  return verify(domainType) || verify(ipType)
}

/**
 * 验证对象是否空对象
 * 2021-06-02 10:57:21
 * @author SnowRock
 * @param o 传入的对象
 */
export function isEmptyObject(o) {
  return Object.keys(o).length <= 0
}

/**
 * 验证是否为有效的邮箱<br>
 * 名称允许汉字、字母、数字，域名只允许英文域名<br>
 * 中文如：杨元庆001Abc@lenovo.com.cn
 * @param {string} val
 * @returns {boolean}
 * @example isValidEmail('123456@qq.com') => true
 */
export function isValidEmail(val) {
  var reg = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/i

  return reg.test(val)
}

/**
 * 验证是否为有效的密码(6-16位字母加数字组合，不能包含空格)
 * @param {string} val
 * @returns {boolean}
 * @example isValidPassword('a23456abc') => true
 */
export function isValidPassword(val) {
  var reg = /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]{6,16}$/

  return reg.test(val)
}

/**
 * 是否为有效的qq
 *
 * @param {string} val
 * @returns {boolean}
 * @example
 *
 * isValidQQ('814563410');
 * // => true
 */
export function isValidQQ(val) {
  var reg = /^[1-9]\d{4,}$/

  return reg.test(val)
}

/**
 * 是否为有效的邮政编码
 *
 * @param val
 * @returns {boolean}
 * @example isValidZipcode('330561') => true
 */
export function isValidZipcode(val) {
  var reg = /^\d{6}$/

  return reg.test(val)
}
