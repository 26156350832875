import { newWechatShareH5 } from '@/services/goods.js'
import { changeURLArg, delUrlParam, getLocal } from '@/utils/common/utils'
import { isWeChat } from '@/utils/common/validate'
import axios from 'axios'
// 微信唤醒APP
export function wxConfigWakeApp() {
  var ajaxUrl =
        'https://gateway.yuetao.vip/center/v1/app/WechatShareH5?share_url=' +
        encodeURIComponent(window.location.href)
  // eslint-disable-next-line no-undef
  $.ajax({
    url: ajaxUrl,
    type: 'GET',
    dataType: 'jsonp',
    success: function(res) {
      // eslint-disable-next-line no-undef
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: res.app_id, // 必填，企业号的唯一标识，此处填写企业号corpid
        timestamp: res.timestamp, // 必填，生成签名的时间戳
        nonceStr: res.noncestr, // 必填，生成签名的随机串
        signature: res.signature, // 必填，签名，见附录1
        jsApiList: ['updateTimelineShareData'], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
        openTagList: ['wx-open-launch-app']
      })
      // eslint-disable-next-line no-undef
      wx.ready(function() { })
    }
  })
}

/**
 * 配置微信JS-SDK用于微信唤起App
 * 2021-06-09 16:49:07
 * @author SnowRock
 */
export function wxWakeApp() {
  axios.get('https://gateway.yuetao.vip/center/v1/app/WechatShareH5', {
    params: {
      share_url: encodeURIComponent(window.location.href)
    }
  }).then(res => {
    let dataJsonP = res.data
    if (dataJsonP.indexOf('(') === 0 && dataJsonP.indexOf(')') === dataJsonP.length - 1) {
      dataJsonP = JSON.parse(dataJsonP.substring(1, dataJsonP.length - 1))
    }
    // eslint-disable-next-line no-undef
    wx.config({
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId: dataJsonP.app_id, // 必填，企业号的唯一标识，此处填写企业号corpid
      timestamp: dataJsonP.timestamp, // 必填，生成签名的时间戳
      nonceStr: dataJsonP.noncestr, // 必填，生成签名的随机串
      signature: dataJsonP.signature, // 必填，签名，见附录1
      jsApiList: ['updateTimelineShareData'], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
      openTagList: ['wx-open-launch-app']
    })
    // eslint-disable-next-line no-undef
    wx.ready(function() { })
  })
}

// 微信分享配置
export function wxConfigInit(data) {
  // 微信配置
  if (!isWeChat()) {
    return
  }
  let linkUrl = ''
  // for (const key in obj.value) {
  //   if (key !== 'env') {
  //     arr.push(key + '=' + obj.value[key])
  //   }
  // }
  // linkUrl = obj.Http + '?' + arr.join('&')
  const codeNumber = getLocal('recode') || ''
  linkUrl = changeURLArg(delUrlParam(delUrlParam(delUrlParam(data.link, 'env'), 'mid'), 'token'), 'codeNumber', codeNumber)
  newWechatShareH5({
    share_url: encodeURIComponent(window.location.href)
  }).then((res) => {
    // console.log('走了',res)
    if (Number(res.code) === 200) {
      // console.log("store里面的时间戳", res.data);
      // eslint-disable-next-line no-undef
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: res.data.app_id, // 必填，企业号的唯一标识，此处填写企业号corpid
        timestamp: res.data.timestamp, // 必填，生成签名的时间戳
        nonceStr: res.data.noncestr, // 必填，生成签名的随机串
        signature: res.data.signature, // 必填，签名，见附录1
        jsApiList: [
          'updateAppMessageShareData',
          'onMenuShareTimeline',
          'onMenuShareAppMessage',
          'updateTimelineShareData'
        ], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
        openTagList: ['wx-open-launch-app']
      })
      // eslint-disable-next-line no-undef
      wx.ready(function() {
        // onMenuShareTimeline
        // eslint-disable-next-line no-undef
        wx.updateAppMessageShareData({
          // 分享到朋友圈
          title: data.title, // 分享标题
          desc: data.desc, // 分享描述
          link: linkUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: data.imgUrl, // 分享图标
          success: function() {
            // console.log("updateTimelineShareData分享成功");
          },
          cancel: function() {
            // console.log("updateTimelineShareData取消分享");
          }
        })
        // onMenuShareAppMessage
        // eslint-disable-next-line no-undef
        wx.updateTimelineShareData({
          // 分享给朋友
          title: data.title, // 分享标题
          desc: data.desc, // 分享描述
          link: linkUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: data.imgUrl, // 分享图标
          success: function() {
            // console.log("updateTimelineShareData分享成功");
          },
          cancel: function() {
            // console.log("updateTimelineShareData取消分享");
          }
        })
        // eslint-disable-next-line no-undef
        wx.onMenuShareTimeline({
          // 分享给朋友
          title: data.title, // 分享标题
          desc: data.desc, // 分享描述
          link: linkUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: data.imgUrl, // 分享图标
          success: function() {
            // console.log("updateAppMessageShareData分享成功");
          },
          cancel: function() {
            // console.log("updateAppMessageShareData取消分享");
          }
        })
        // eslint-disable-next-line no-undef
        wx.onMenuShareAppMessage({
          // 分享给朋友
          title: data.title, // 分享标题
          desc: data.desc, // 分享描述
          link: linkUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: data.imgUrl, // 分享图标
          success: function() {
            // console.log("updateTimelineShareData分享成功");
          },
          cancel: function() {
            // console.log("updateTimelineShareData取消分享");
          }
        })
      })
      // eslint-disable-next-line no-undef
      wx.error(function(res) {
        // console.log("store配置失败", res);
        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名
      })
    }
  })
}
// 微信分享默认配置
export function wxConfigDefault() {
  // 悦淘商城默认分享
  if (isWeChat()) {
    return
  }
  // eslint-disable-next-line no-undef
  wx.ready(function() {
    // eslint-disable-next-line no-undef
    wx.onMenuShareTimeline({
      // 分享到朋友圈
      title: '悦淘商城', // 分享标题
      desc: '精致生活的美好体验', // 分享描述
      link: 'https://web.yuetao.vip/', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
      imgUrl:
                'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-02-17/13/yuelvhuiQJwXComJ821581918103.jpg' // 分享图标
    })
    // eslint-disable-next-line no-undef
    wx.onMenuShareAppMessage({
      // 分享给朋友
      title: '悦淘商城', // 分享标题
      desc: '精致生活的美好体验', // 分享描述
      link: 'https://web.yuetao.vip/', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
      imgUrl: 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-02-17/13/yuelvhuiQJwXComJ821581918103.jpg' // 分享图标
    })
  })
}

// 禁用微信分享
export function closeShare() {
  if (typeof WeixinJSBridge === 'undefined') {
    if (document.addEventListener) {
      document.addEventListener(
        'WeixinJSBridgeReady',
        // eslint-disable-next-line no-undef
        WeixinJSBridge.call('hideOptionMenu'),
        false
      )
    } else if (document.attachEvent) {
      document.attachEvent(
        'WeixinJSBridgeReady',
        // eslint-disable-next-line no-undef
        WeixinJSBridge.call('hideOptionMenu')
      )
      document.attachEvent(
        'onWeixinJSBridgeReady',
        // eslint-disable-next-line no-undef
        WeixinJSBridge.call('hideOptionMenu')
      )
    }
  } else {
    // eslint-disable-next-line no-undef
    WeixinJSBridge.call('hideOptionMenu')
  }
}

// 启用微信分享
export function showShare() {
  if (typeof WeixinJSBridge === 'undefined') {
    if (document.addEventListener) {
      document.addEventListener(
        'WeixinJSBridgeReady',
        // eslint-disable-next-line no-undef
        WeixinJSBridge.call('showOptionMenu'),
        false
      )
    } else if (document.attachEvent) {
      document.attachEvent(
        'WeixinJSBridgeReady',
        // eslint-disable-next-line no-undef
        WeixinJSBridge.call('showOptionMenu')
      )
      document.attachEvent(
        'onWeixinJSBridgeReady',
        // eslint-disable-next-line no-undef
        WeixinJSBridge.call('showOptionMenu')
      )
    }
  } else {
    // eslint-disable-next-line no-undef
    WeixinJSBridge.call('showOptionMenu')
  }
}
