import request from '@/services/request.js'
function targetUrl(url) {
  return '/shop/v1' + url
}

// 获取自营商品详情
export function getZyGoodsDetail(data) {
  return request({
    url: '/mall/getProductBuyDatailV4',
    method: 'post',
    data,
    prefix: ''
  })
}

// 获取自营商品基本信息(新接口)
export function productInfo(data) {
  return request({
    url: '/product/info',
    method: 'post',
    data,
    prefix: '',
    headers: {
      hideLoading: true
    }
  })
}

// 商品领券购买

export function getCouponBuyGoods(data) {
  return request({
    url: '/product/getCouponBuyGoods',
    method: 'post',
    data,
    prefix: '',
    headers: {
      hideLoading: true
    }
  })
}

// product/getCouponBuyGoods

// 获取自营商品详情(新接口)
export function productContent(data) {
  return request({
    url: '/product/content',
    method: 'post',
    data,
    prefix: '',
    headers: {
      hideLoading: true
    }
  })
}

// 获取自营商品评论(新接口)
export function productComment(data) {
  return request({
    url: '/product/comment',
    method: 'post',
    data,
    prefix: '',
    headers: {
      hideLoading: true
    }
  })
}

// 获取秒杀商品详情
export function getMsGoodsDetail(data) {
  return request({
    url: '/mall/flashDetail',
    method: 'post',
    data,
    prefix: '',
    headers: {
      hideLoading: true
    }
  })
}

// 短信预约
export function flashGoodsReserve(data) {
  return request({
    url: '/mall/flashGoodsReserve',
    method: 'post',
    data,
    prefix: ''
  })
}

// 获取CPS 京东 淘宝 拼多多
export function getCpsGoodsDetail(data) {
  return request({
    url: '/outside/CpsGoodsDetail',
    method: 'post',
    data,
    prefix: ''
  })
}

// 排行榜详情
export function getQueryGoodsDetail(data) {
  return request({
    url: '/taobk/v1/queryGoodsDetail',
    method: 'post',
    data,
    prefix: ''
  })
}

// 排行榜推荐列表
export function querySimilerGoodsByOpen(data) {
  return request({
    url: '/taobk/v1/querySimilerGoodsByOpen',
    method: 'post',
    data,
    prefix: ''
  })
}

// 排行榜领券购买
export function linkTransfer(data) {
  return request({
    url: '/taobk/v3/linkTransfer',
    method: 'post',
    data,
    prefix: ''
  })
}

// 京东 淘宝 拼多多 以下接口废弃
// 获取京东商品详情
export function getJDGoodsDetail(data) {
  return request({
    url: '/outside/jdGoodsInfo',
    method: 'post',
    data,
    prefix: ''
  })
}

// 获取小米商品详情
export function getXMGoodsDetail(data) {
  return request({
    url: targetUrl('/cpsreforge/xiaomi/v1/getProductDetail'),
    method: 'post',
    data,
    prefix: ''
  })
}

// 跳转小米链接地址
export function getLoginLink(data) {
  return request({
    url: targetUrl('/cpsreforge/xiaomi/v1/getLoginLink'),
    method: 'post',
    data,
    prefix: ''
  })
}

// 跳转小米链接地址
export function goodsShareData(data) {
  return request({
    url: targetUrl('/outside/allCpsAndSelfGoodsShareData'),
    method: 'post',
    data,
    prefix: ''
  })
}

// 获取拼多多商品详情
export function getPDDGoodsDetail(data) {
  return request({
    url: '/outside/pddGoodsInfo',
    method: 'post',
    data,
    prefix: ''
  })
}

// 获取淘宝商品详情
export function getTBGoodsDetail(data) {
  return request({
    url: '/outside/tbkdetail',
    method: 'post',
    data,
    prefix: ''
  })
}

// 获取唯品会商品详情
export function getWPHGoodsDetail(data) {
  return request({
    url: '/outside/wph/getGoodDetail',
    method: 'post',
    data,
    prefix: ''
  })
}

// 获取苏宁商品详情
export function getSNGoodsDetail(data) {
  return request({
    url: '/outside/suning/getGoodsDetail',
    method: 'post',
    data,
    prefix: ''
  })
}

// 获取商品详情顶部文案
export function textData(data) {
  return request({
    url: '/mall/h5DataList',
    method: 'post',
    data,
    prefix: '',
    headers: {
      hideLoading: true
    }
  })
}

// 获取预估成长值文案
export function growthValueRemind(data) {
  return request({
    url: '/mall/getGrowthValueRemind',
    method: 'get',
    data,
    prefix: ''
  })
}

// 获取评论列表
export function commentList(data) {
  return request({
    url: '/mall/productCommentList',
    method: 'post',
    data,
    prefix: ''
  })
}

// 加入购物车
export function addShopCar(data) {
  return request({
    url: '/product/addToCart',
    method: 'post',
    data,
    prefix: '',
    message: {
      400: '该商品不能加入购物车'
    }
  })
}

// 获取购物车列表
export function getShopCarList(data) {
  return request({
    url: '/mall/cart/V2/list',
    method: 'post',
    data,
    prefix: '',
    headers: {
      hideLoading: true
    },
    hideMessage: true
  })
}

// 获取失效商品列表
export function getLoseGoods(data) {
  return request({
    url: '/mall/cart/getLoseEfficacyCartList',
    method: 'post',
    data,
    prefix: ''
  })
}

// export function addBrowsingRecord(data) {
//     return request({
//         url: '/mall/addBrowsingRecord',
//         method: 'post',
//         data,
//         prefix: ''
//     })
// }

// 获取失效商品列表
export function newList(data) {
  return request({
    url: '/mall/cart/V2/newList',
    method: 'post',
    data,
    prefix: ''
  })
}
// 删除 商品
export function shopDeletes(data) {
  return request({
    url: '/mall/cart/V2/delete',
    method: 'post',
    data,
    prefix: ''
  })
}
// 获取推荐商品列表
export function getGoodGoods(data) {
  return request({
    url: '/mall/V2/getAboutShopCart',
    method: 'post',
    data,
    prefix: ''
  })
}

// 处理购物车商品数量
export function handleGoodsCount(data) {
  return request({
    url: '/mall/cart/updateV4',
    method: 'post',
    data,
    prefix: ''
  })
}

// 改变购物车选中状态
export function changeChoseStatus(data) {
  return request({
    url: '/mall/cart/V2/updateCartStatus',
    method: 'post',
    data,
    prefix: ''
  })
}

// 删除购物车商品
export function deleteGoods(data) {
  return request({
    url: '/mall/cart/delete',
    method: 'post',
    data,
    prefix: ''
  })
}

// 获取微信配置信息
export function getWxConfig(data) {
  return request({
    url: '/app/WechatShareH5',
    method: 'get',
    params: data,
    prefix: ''
  })
}

// 获取微信配置信息
export function newWechatShareH5(data) {
  return request({
    url: '/app/newWechatShareH5',
    method: 'get',
    params: data,
    prefix: ''
  })
}

// 规格改变时，获取商品信息
export function skuChangeInfo(data) {
  return request({
    url: '/product/skuInfo',
    method: 'post',
    data,
    prefix: '',
    headers: {
      hideLoading: true
    }
  })
}

// 规格改变时，获取商品信息
export function getSkuDetails(data) {
  return request({
    url: 'product/getSkuDetails',
    method: 'post',
    data,
    prefix: '',
    headers: {
      hideLoading: true
    }
  })
}

// 获取购物车数量
export function getGoodsNumber(data) {
  return request({
    url: '/mall/cart/getGoodsNumber',
    method: 'post',
    data,
    prefix: '',
    headers: {
      hideLoading: true
    }
  })
}
// get拼团商品列表
export function getGoodsList(data) {
  return request({
    url: '/hd/spell/getSpellGoodsItem',
    method: 'get',
    data
  })
}
// get拼团商品详情
export function getGoodsDetail(data) {
  return request({
    url: '/hd/assembleInfo',
    method: 'get',
    params: data
  })
}

// 拼团订单确认
export function spellGroupOrderConfirm(data) {
  return request({
    url: '/hd/firmOrder',
    method: 'post',
    data
  })
}
// get拼团成员
export function getSpellGroupPersonnel(data) {
  return request({
    url: '/hd/spell/getSpellOrderMember',
    method: 'get',
    params: data
  })
}
// 拼团订单详情
export function getSpellGroupDescribe(data) {
  return request({
    url: '/hd/getOrdersnTeamInfo',
    method: 'post',
    data
  })
}
// 拼团提交订单
export function SpellGroupOrderSubmit(data) {
  return request({
    url: '/hd/initLnitiateAGroupV2',
    // url: '/hd/initLnitiateAGroup',
    method: 'post',
    data
  })
}
// 拼团支付订单
export function SpellGroupOrderPay(data) {
  return request({
    url: '/app/newMallPayV4',
    method: 'post',
    data
  })
}

// 必买爆款接口

export function getGiftGoods(data) {
  return request({
    url: '/mall/getGiftGoods',
    method: 'post',
    params: data,
    prefix: ''
  })
}

/**
 * 必买爆款接口 - 线路 - 仰生活
 * @param data
 * @returns {Promise<*|undefined>}
 */
export function fetchTravelGoods(data) {
  return request({
    url: '/product/getTravelGoods',
    method: 'post',
    params: data,
    prefix: ''
  })
}

// 必买爆款接口

export function newMembershipLevelList(data) {
  return request({
    url: '/mall/newMembershipLevelList',
    method: 'post',
    params: data,
    prefix: ''
  })
}

// 超值换购列表
export function getBigGiftBigList(data) {
  return request({
    url: '/mall/getBigGiftBigList',
    method: 'post',
    params: data,
    prefix: ''
  })
}
