export default [
  {
    path: '/acthds',
    name: 'acthds',
    component: () => import ('@/views/activity/index'), // 懒加载方式
    meta: {
      title: '活动'
    }
  }, {
    path: '/voucherCenter',
    name: 'voucherCenter',
    component: () => import ('@/views/activity/voucherCenter'), // 懒加载方式
    meta: {
      title: '领券中心'
    }
  }, { // 新版领劵中心
    path: '/newCoupons',
    name: 'newCoupon',
    component: () => import ('@/views/activity/newCoupon'), // 懒加载方式
    meta: {
      title: '领券中心'
    }
  }, {
    path: '/TakeWithoutPaying',
    name: 'takewithoutpaying',
    component: () => import ('@/views/activity/TakeWithoutPaying'), // 懒加载方式
    meta: {
      title: '每月白拿'
    }
  }, {
    path: '/dalibao',
    name: 'dalibao',
    component: () => import ('@/views/activity/dalibao/index'), // 懒加载方式
    meta: {
      title: '会场专享大礼包'
    }
  }, {
    path: '/dalibaolist',
    name: 'list',
    component: () => import ('@/views/activity/dalibao/list'), // 懒加载方式
    meta: {
      title: '会场专享大礼包'
    }
  }, {
    path: '/gaode',
    name: 'gaode',
    component: () => import ('@/views/activity/gaode/index'), // 懒加载方式
    meta: {
      title: '七夕新人大礼包'
    }
  }, {
    path: '/gaodelist',
    name: 'gaodelist',
    component: () => import ('@/views/activity/gaode/gaodelist'), // 懒加载方式
    meta: {
      title: '七夕新人大礼包'
    }
  },
  {
    path: '/SpellGroupIndex',
    name: 'SpellGroup',
    component: () => import ('@/views/SpellGroup/index'), // 懒加载方式
    meta: {
      title: '发起拼团',
      requireAuth: false
    }
  },
  {
    path: '/goodsListDetail',
    name: 'goodsListDetail',
    component: () => import ('@/views/SpellGroup/goodsListDetail'), // 懒加载方式
    meta: {
      title: '拼团商品详情',
      requireAuth: true
    }
  },
  {
    path: '/SpellGroupDetail',
    name: 'SpellGroupDetail',
    component: () => import ('@/views/SpellGroup/SpellGroupDetail'), // 懒加载方式
    meta: {
      title: '拼团',
      requireAuth: true
    }
  },
  {
    path: '/yEleven',
    name: 'yEleven',
    component: () => import ('@/views/activity/yEleven/index'), // 懒加载方式
    meta: {
      title: '双十二悦淘宠粉节'
      // requireAuth: true,
    }
  },
  {
    path: '/bVenue',
    name: '',
    component: () => import ('@/views/activity/yEleven/Bvenue'),
    meta: {
      title: '双十二分会场' // 标题
    }
  },
  {
    path: '/myTeam',
    name: '',
    component: () => import ('@/views/activity/yEleven/myTeam'),
    meta: {
      title: '我的团队' // 标题

    }
  },
  {
    path: '/richList',
    name: '',
    component: () => import ('@/views/activity/yEleven/richList'),
    meta: {
      title: '悦淘创富榜' // 标题

    }
  },
  {
    path: '/JumpLink',
    name: '',
    component: () => import ('@/views/activity/yEleven/JumpLink'),
    meta: {
      title: '请稍等...' // 标题

    }
  },
  {
    path: '/Policy',
    name: '',
    component: () => import ('@/views/user/Policy'),
    meta: {
      title: '隐私条款' // 标题

    }
  },
  {
    path: '/DivideBonusActivity',
    name: 'DivideBonusActivity',
    component: () => import('@/views/activity/divide-bonus-activity/index'),
    meta: {
      title: '活动'
    }
  },
  {
    path: '/ParticipationRecord',
    name: 'ParticipationRecord',
    component: () => import('@/views/activity/divide-bonus-activity/participation-record'),
    meta: {
      title: '瓜分记录'
    }
  },
  {
    path: '/bonus-promotion',
    name: 'BonusPromotion',
    component: () => import('@/views/activity/divide-bonus-activity/bonus-promotion'),
    meta: {
      title: '促销悦豆'
    }
  },
  {
    path: '/LandingPageMemberWeek',
    name: 'LandingPageMemberWeek',
    component: () => import('@/views/activity/landing-page-member-week/index.vue'),
    meta: {
      title: '会员周'
    }
  },
  {
    path: '/jd-activity-618',
    name: 'JdActivity618',
    component: () => import('@/views/activity/jd/jdActivity'),
    meta: {
      title: '京东618'
    }
  },
  {
    path: '/admire-life',
    name: 'AdmireLife',
    component: () => import('@/views/activity/admire-life/index'),
    meta: {
      title: '线路'
    }
  },
  {
    path: '/yt-come-on-season',
    name: 'YtComeOnSeason',
    component: () => import('@/views/activity/yt-come-on-season/index'),
    meta: {
      title: '悦淘加油季'
    }
  },
  {
    path: '/yt-service-cost',
    name: 'YtServiceCost',
    component: () => import('@/views/activity/yt-service-cost/index'),
    meta: {
      title: '账户费用',
      requireAuth: true
    }
  },
  {
    path: '/yt-service-cost-success',
    name: 'YtServiceCostSuccess',
    component: () => import('@/views/activity/yt-service-cost/result'),
    meta: {
      title: '支付成功',
      requireAuth: true
    }
  },
  {
    path: '/policy-yt',
    name: 'ActivityPolicy',
    component: () => import('@/views/activity/activity-policy/index'),
    meta: {
      title: 'C店金秋节'
    }
  },
  {
    path: '/c-shop-malls',
    name: 'CShopMalls',
    component: () => import('@/views/activity/c-shop-malls/index'),
    meta: {
      title: 'C店新选活动专场'
    }
  }
]
