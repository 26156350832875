/*
 * @Author: your name
 * @Date: 2020-09-29 11:04:56
 * @LastEditTime: 2020-09-29 16:16:02
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /yue_quanzhan_h5/src/main.js
 */
/**
 * @description: 项目入口文件
 * @author: nowThen
 */

import Vue from 'vue'
import App from './App.vue'
import router from './routers/'
import './routers/intercept'
import store from './stores'
import * as filters from './filters/'
import 'lib-flexible'

import './styles/main.less'

import FastClick from 'fastclick'
import './utils/rem'
// import './utils/v-console' // 引入vconsole

// 友盟统计埋点插件
import uweb from 'vue-uweb'

import Router from 'vue-router'

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const options = {
  siteId: '1279441928',
  debug: true,
  autoPageview: true,
  src: ''
}
Vue.use(uweb, options)

FastClick.attach(document.body)
// input点击不灵敏
FastClick.prototype.focus = function(targetElement) {
  targetElement.focus()
}
// 禁止打开该项
// Vue.config.errorHandler = (err, vm, info) => {
//   console.log(err)
// }
Vue.config.devtools = process.env.NODE_ENV !== 'production'
Vue.config.productionTip = process.env.NODE_ENV === 'production'

window.EventBus = new Vue()

// 注册过滤器
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key])
})

window.myVue = new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
