export default [
  {
    path: '/pay',
    name: 'pay',
    component: () => import ('@/views/pay/pay'),
    meta: {
      title: '收银台',
      requireAuth: false,
      keepAlive: false
    }
  },
  {
    path: '/pay-consequent',
    name: 'pay-consequent',
    component: () => import ('@/views/pay/payConsequent'),
    meta: {
      title: '支付结果',
      requireAuth: false,
      keepAlive: false
    }
  }
]
