/**
 * @description: 路由
 *
 */

import Vue from 'vue'
import Router from 'vue-router'
import goodsDetailPublic from './moudles/goodsDetailPublic'
import cps from './moudles/cps'
import activity from './moudles/activity'
import localLife from './moudles/localLife'
import ada from './moudles/ada'
import pay from './moudles/pay'
import order from '@/routers/moudles/order'

Vue.use(Router)

const routes = [
  {
    path: '/yt-optimization',
    name: 'YtOptimization',
    component: () => import('@/views/yt-optimization'),
    meta: {
      title: '悦淘优选', // 标题
      requireAuth: false, // 登录权限
      keepAlive: false
    }
  },
  {
    path: '/yt-optimization-malls',
    name: 'YtOptimization',
    component: () => import('@/views/yt-optimization/mall-list'),
    meta: {
      title: '悦淘优选商品', // 标题
      requireAuth: false, // 登录权限
      keepAlive: false
    }
  },
  {
    path: '/',
    name: 'index',
    component: () => import('@/views/index'),
    meta: {
      title: '悦淘商城', // 标题
      requireAuth: false, // 登录权限
      keepAlive: false
    },

    // 老得 首页

    // children: [{
    //     path: '/',
    //     name: 'recommend',
    //     component: () =>
    //         import ('@/views/recommend'),
    //     meta: {
    //         title: '悦淘商城', // 标题
    //         requireAuth: false, // 登录权限
    //         keepAlive: false,
    //     }
    // }]
    // 新的 首页
    children: [
      {
        path: '/',
        name: 'recommend',
        component: () => import('@/views/yueTao'),
        meta: {
          title: '悦淘商城', // 标题
          requireAuth: false, // 登录权限
          keepAlive: false
        }
      }
    ]
  },
  // {
  //     path: '/lao',
  //     name: 'recommend',
  //     component: () =>
  //         import ('@/views/recommend'),
  //     meta: {
  //         title: '', // 标题
  //     }
  // },
  // 首页秒杀
  {
    path: '/secondKill',
    name: 'secondKill',
    component: () => import('@/views/secondKill/index'),
    meta: {
      title: '精品秒杀' // 标题
    }
  },
  // 首页 排名榜
  {
    path: '/ranking',
    name: 'ranking',
    component: () => import('@/views/yueTaoRanking/ranking'),
    meta: {
      title: '排名榜' // 标题
    }
  },
  // 首页 爆品拼团
  {
    path: '/group',
    name: 'group',
    component: () => import('@/views/yueTaoRanking/group'),
    meta: {
      title: '爆品拼团' // 标题
    }
  },
  // 首页 限时半价
  {
    path: '/price',
    name: 'price',
    component: () => import('@/views/yueTaoRanking/price'),
    meta: {
      title: '限时半价' // 标题
    }
  },
  // 首页 个护清洁
  {
    path: '/clean',
    name: 'clean',
    component: () => import('@/views/yueTaoRanking/clean'),
    meta: {
      title: '个护清洁' // 标题
    }
  },

  // 首页 金钢位
  {
    path: '/pagePublic',
    name: 'clean',
    component: () => import('@/views/tabbox/index'),
    meta: {
      title: '' // 标题
    }
  },

  // 首页 分类
  {
    path: '/classification',
    name: 'classification',
    component: () => import('@/views/classification/index'),
    meta: {
      title: '分类' // 标题
    }
  },
  // 首页 分类 二级
  {
    path: '/ficationList',
    name: 'classificationList',
    component: () => import('@/views/classification/classificationList'),
    meta: {
      title: '分类详情' // 标题
    }
  },

  // 首页 悦淘国际
  {
    path: '/yueTaoInternational',
    name: 'yueTaoInternational',
    component: () => import('@/views/yueTaoInternational/index'),
    meta: {
      title: '悦淘国际' // 标题
    }
  },
  // 品牌墙
  {
    path: '/brandList',
    name: 'brandList',
    component: () => import('@/views/yueTaoInternational/brandList'),
    meta: {
      title: '品牌墙' // 标题
    }
  },

  // 数码家电 ,日用家居, 母婴玩具
  {
    path: '/digital',
    name: 'digital',
    component: () => import('@/views/digitalAppliances/index'),
    meta: {
      title: '' // 标题
    }
  },
  // 山水 总相逢
  {
    path: '/digitalActivities',
    name: 'digitalActivities',
    component: () => import('@/views/digitalAppliances/digitalActivities'),
    meta: {
      title: '' // 标题
    }
  },
  // 公共 侧边栏 筛选页面
  {
    path: '/sidebar',
    name: 'sidebar',
    component: () => import('@/views/digitalAppliances/sidebar'),
    meta: {
      title: '' // 标题
    }
  },
  // //详情
  // {
  //     path: '/detailsList',
  //     name: 'detailsList',
  //     component: () =>
  //         import ('@/views/yueTaoInternational/detailsList'),
  //     meta: {
  //         title: '详情', // 标题
  //     }
  // },

  {
    path: '/fresh',
    name: 'fresh',
    component: () => import('@/views/fresh'),
    meta: {
      title: '生鲜', // 标题
      requireAuth: false, // 登录权限
      keepAlive: false
    }
  },
  {
    path: '/house',
    name: 'house',
    component: () => import('@/views/house'),
    meta: {
      title: '家居', // 标题
      requireAuth: false, // 登录权限
      keepAlive: false
    }
  },
  {
    path: '/beauty',
    name: 'beauty',
    component: () => import('@/views/beauty'),
    meta: {
      title: '美妆', // 标题
      requireAuth: false, // 登录权限
      keepAlive: false
    }
  },
  {
    path: '/goodStore',
    name: 'goodStore',
    component: () => import('@/views/goodStore'),
    meta: {
      title: '大牌清仓', // 好衣库
      requireAuth: false, // 登录权限
      keepAlive: false
    }
  },
  {
    path: '/goodsList',
    name: 'goodsList',
    component: () => import('@/views/goods/goods-list'), // 懒加载方式
    meta: {
      title: '商品列表'
    }
  },
  // 好衣库
  {
    path: '/clothes',
    name: 'clothes',
    component: () => import('@/views/goods/clothes'), // 懒加载方式
    meta: {
      title: ''
    }
  },
  {
    path: '/clothesHall',
    name: 'clothesHall',
    component: () => import('@/views/goods/clothesHall'), // 懒加载方式
    meta: {
      title: ''
    }
  },
  {
    path: '/gaoyong',
    name: 'HighCommission',
    component: () => import('@/views/goods/high-commission-list'), // 懒加载方式
    meta: {
      title: '高佣商品'
    }
  },
  {
    path: '/jd',
    name: 'jd',
    component: () => import('@/views/goods/jd-list'), // 懒加载方式
    meta: {
      title: '京东'
    }
  },
  // jd 详情 list
  {
    path: '/jdDetailsList',
    name: 'jdDetailsList',
    component: () => import('@/views/goods/jdDetailsList'), // 懒加载方式
    meta: {
      title: '京东'
    }
  },

  // 必买爆款
  {
    path: '/mustBuyGoods',
    name: 'mustBuyGoods',
    component: () => import('@/views/goods/must-buy'), // 懒加载方式
    meta: {
      title: '必买爆款'
    }
  },

  // 开团免费拿
  {
    path: '/openGroup',
    name: 'openGroup',
    component: () => import('@/views/goods/open-group'), // 懒加载方式
    meta: {
      title: '开团免费拿'
    }
  },
  // 开团免费拿详情
  {
    path: '/openGroupList',
    name: 'openGroupList',
    component: () => import('@/views/goods/open-group-list'), // 懒加载方式
    meta: {
      title: '开团详情'
    }
  },

  {
    path: '/pdd',
    name: 'pdd',
    component: () => import('@/views/goods/pdd-list'), // 懒加载方式
    meta: {
      title: '拼多多'
    }
  },
  {
    path: '/liveList',
    name: 'live-list',
    component: () => import('@/views/goods/live-list'), // 懒加载方式
    meta: {
      title: '必卖爆款'
    }
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('@/views/error'), // 懒加载方式
    meta: {
      title: '404页'
    }
  },
  {
    path: '/cart',
    name: 'cart',
    component: () => import('@/views/cart'),
    meta: {
      title: '购物车',
      requireAuth: false,
      keepAlive: false
    }
  },
  {
    path: '/video',
    name: 'video',
    component: () => import('@/views/video/index'),
    meta: {
      title: '直播',
      requireAuth: true,
      keepAlive: false
    }
  },
  {
    path: '/videodetail',
    name: 'LiveVideoDetail',
    component: () => import('@/views/video/detail'),
    meta: {
      title: '直播详情',
      requireAuth: false,
      keepAlive: false
    }
  },
  {
    path: '/livedetail',
    name: 'LiveDetail',
    component: () => import('@/views/live/live'),
    meta: {
      title: '直播详情',
      requireAuth: false,
      keepAlive: false
    }
  },
  {
    path: '/couponPage',
    name: 'couponPage',
    component: () => import('@/views/couponPage/index'),
    meta: {
      title: '优惠券', // 标题
      requireAuth: false, // 登录权限
      keepAlive: false
    }
  },
  {
    path: '/detailActivity',
    name: 'detailActivity',
    component: () => import('@/views/couponPage/detailActivity'),
    meta: {
      title: '领取活动专属券', // 标题
      requireAuth: false, // 登录权限
      keepAlive: false
    }
  },
  {
    path: '/Activitylist',
    name: 'ActivityList',
    component: () => import('@/views/couponPage/ActivityList'),
    meta: {
      title: '活动专属', // 标题
      requireAuth: false, // 登录权限
      keepAlive: false
    }
  },
  {
    path: '/testzhuanyong/clear',
    name: 'clearStorage',
    component: () => import('@/views/clear-storage/clear'),
    meta: {
      title: '清空缓存', // 标题
      requireAuth: false, // 登录权限
      keepAlive: false
    }
  },
  {
    path: '/spell',
    name: 'spell',
    component: () => import('@/views/spell'),
    meta: {
      title: '悦淘' // 标题
    }
  },
  {
    path: '/MustBuy',
    name: '',
    component: () => import('@/views/MustBuy'),
    meta: {
      title: '必买爆款', // 标题
      requireAuth: true // 登录权限
    }
  },

  {
    path: '/jdSpecialOffer',
    name: '',
    component: () => import('@/views/temporary/jdSpecialOffer.vue'),
    meta: {
      title: '京东特价' // 标题
    }
  },
  {
    path: '/koala',
    name: '',
    component: () => import('@/views/koala/index.vue'),
    meta: {
      title: '考拉海购' // 标题
    }
  },
  {
    path: '/shopclassify',
    name: '',
    component: () => import('@/views/koala/shop-classify.vue'),
    meta: {
      title: '考拉海购列表' // 标题
    }
  },
  {
    path: '/ruleDescription',
    name: '',
    component: () => import('@/views/goodsDetail/ruleDescription.vue'),
    meta: {
      title: '预估成长值' // 标题
    }
  },
  {
    path: '/CancellationNotice',
    name: '',
    component: () => import('@/views/user/CancellationNotice.vue'),
    meta: {
      title: '悦淘注销须知' // 标题
    }
  },
  {
    path: '/oilImg',
    name: 'oilImg',
    component: () => import('@/views/user/oilImg'),
    meta: {
      title: '大人加油' // 标题
    }
  },
  {
    path: '/nftOrderList',
    name: 'nftOrderList',
    component: () => import('@/views/user/nftOrderList'),
    meta: {
      title: '元宇宙订单' // 标题
    }
  },
  {
    path: '/energyMall',
    name: 'energyMall',
    component: () => import('@/views/user/energyMall/index'),
    meta: {
      title: '能量商城' // 标题
    }
  },
  {
    path: '/grandTotal',
    name: 'grandTotal',
    component: () => import('@/views/user/energyMall/grandTotal'),
    meta: {
      title: '历史累计能量' // 标题
    }
  },
  {
    path: '/expire',
    name: 'expire',
    component: () => import('@/views/user/energyMall/expire'),
    meta: {
      title: '即将到期能量' // 标题
    }
  },
  {
    path: '/consume',
    name: 'consume',
    component: () => import('@/views/user/energyMall/consume'),
    meta: {
      title: '消耗能量' // 标题
    }
  },
  {
    path: '/details',
    name: 'details',
    component: () => import('@/views/user/energyMall/details'),
    meta: {
      title: '能量详情' // 标题
    }
  },
  {
    path: '/activation',
    name: 'activation',
    component: () => import('@/views/couponPage/activation'),
    meta: {
      title: '优惠券激活' // 标题
    }
  },
  {
    path: '/recordList',
    name: 'recordList',
    component: () => import('@/views/couponPage/recordList'),
    meta: {
      title: '兑换纪录' // 标题
    }
  },
  {
    path: '/wineList',
    name: 'wineList',
    component: () => import('@/views/user/wineList'),
    meta: {
      title: '庆功酒订单' // 标题
    }
  },
  {
    path: '/wineDetail',
    name: 'wineDetail',
    component: () => import('@/views/user/wineDetail'),
    meta: {
      title: '庆功酒订单' // 标题 travelList
    }
  },
  {
    path: '/traveList',
    name: 'traveList',
    component: () => import('@/views/user/traveList'),
    meta: {
      title: '三万旅游确权订单' // 标题
    }
  },
  {
    path: '/traveDetail',
    name: 'traveDetail',
    component: () => import('@/views/user/traveDetail'),
    meta: {
      title: '三万旅游确权订单' // 标题
    }
  },
  {
    path: '/crowdFunding',
    name: 'crowdFunding',
    component: () => import('@/views/crowdFunding'),
    meta: {
      requireAuth:false,
      title: '众筹' // 标题
    }
  },
  {
    path: '*',
    redirect: 'error'
  },
  ...goodsDetailPublic,
  ...cps,
  ...activity,
  ...ada,
  ...localLife,
  ...order,
  ...pay
]

const router = new Router({
  mode: 'history',
  // mode: 'hash',
  routes
})

export default router
