function getBrowserInfo() {
  var agent = navigator.userAgent.toLowerCase()

  var regStr_ie = /msie [\d.]+;/gi
  var regStr_ff = /firefox\/[\d.]+/gi
  var regStr_chrome = /chrome\/[\d.]+/gi
  var regStr_saf = /safari\/[\d.]+/gi
  let arr = []

  // IE
  if (agent.indexOf('msie') > 0) {
    arr = agent.match(regStr_ie)
  }

  // firefox
  if (agent.indexOf('firefox') > 0) {
    arr = agent.match(regStr_ff)
  }

  // Chrome
  if (agent.indexOf('chrome') > 0) {
    arr = agent.match(regStr_chrome)
  }

  // Safari
  if (agent.indexOf('safari') > 0 && agent.indexOf('chrome') < 0) {
    arr = agent.match(regStr_saf)
  }
  if (arr.length !== 0) {
    return {
      browser: arr[0].split('/')[0],
      browser_version: arr[0].split('/')[1]
    }
  } else {
    return null
  }
}

// 获取UUID
function getUuid() {
  const s = []; let uuidA = ''
  // var s = [];
  const hexDigits = '0123456789abcdef'
  for (let i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
  }
  s[14] = '4'
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1)
  s[8] = s[13] = s[18] = s[23] = '-'
  uuidA = s.join('')
  return uuidA
}

// 获取操作系统
function getOsInfo(Agent) {
  var userAgent = Agent.toLowerCase()
  var name = 'Unknown'
  var version = 'Unknown'
  if (userAgent.indexOf('win') > -1) {
    name = 'Windows'
    if (userAgent.indexOf('windows nt 5.0') > -1) {
      version = 'Windows 2000'
    } else if (userAgent.indexOf('windows nt 5.1') > -1 || userAgent.indexOf('windows nt 5.2') > -1) {
      version = 'Windows XP'
    } else if (userAgent.indexOf('windows nt 6.0') > -1) {
      version = 'Windows Vista'
    } else if (userAgent.indexOf('windows nt 6.1') > -1 || userAgent.indexOf('windows 7') > -1) {
      version = 'Windows 7'
    } else if (userAgent.indexOf('windows nt 6.2') > -1 || userAgent.indexOf('windows 8') > -1) {
      version = 'Windows 8'
    } else if (userAgent.indexOf('windows nt 6.3') > -1) {
      version = 'Windows 8.1'
    } else if (userAgent.indexOf('windows nt 6.2') > -1 || userAgent.indexOf('windows nt 10.0') > -1) {
      version = 'Windows 10'
    } else {
      version = 'Unknown'
    }
  } else if (userAgent.indexOf('iphone') > -1) {
    name = 'Iphone'
  } else if (userAgent.indexOf('mac') > -1) {
    name = 'Mac'
  } else if (userAgent.indexOf('x11') > -1 || userAgent.indexOf('unix') > -1 || userAgent.indexOf('sunname') > -1 || userAgent.indexOf('bsd') > -1) {
    name = 'Unix'
  } else if (userAgent.indexOf('linux') > -1) {
    if (userAgent.indexOf('android') > -1) {
      name = 'Android'
    } else {
      name = 'Linux'
    }
  } else {
    name = 'Unknown'
  }
  return { name, version }
}

export function setAppInfo() {
  const appInfo = {
    appName: 'yuetao',
    clientType: 'h5',
    version: '',
    systemVersion: '',
    systemType: '', // 获取操作系统
    codepushVersion: '',
    model: '',
    uuid: getUuid(),
    brand: ''
  }

  // 判断数组中是否包含某字符串
  // eslint-disable-next-line no-extend-native
  Array.prototype.contains = function(needle) {
    for (i in this) {
      if (this[i].indexOf(needle) > 0) {
        return i
      }
    }
    return -1
  }

  var device_type = navigator.userAgent // 获取userAgent信息
  appInfo.systemType = getOsInfo(device_type).name
  // document.write(device_type); //打印到页面
  // eslint-disable-next-line no-undef
  var md = new MobileDetect(device_type) // 初始化mobile-detect
  var os = md.os() // 获取系统

  var model = ''
  if (os === 'iOS') { // ios系统的处理
    os = +md.version('iPhone')
    model = md.mobile()
  } else if (os === 'AndroidOS') { // Android系统的处理
    os = md.os() + md.version('Android')
    var sss = device_type.split(';')
    var i = sss.contains('Build/')
    if (i > -1) {
      model = sss[i].substring(0, sss[i].indexOf('Build/'))
    }
  }
  appInfo.brand = md.phone()
  appInfo.systemVersion = os
  appInfo.model = model
  return appInfo
}

// 设置注册是埋点
export function setAnalytics(option) {
  const browserInfo = getBrowserInfo()
  const appInfo = setAppInfo()
  const obj = {
    device_id: appInfo.uuid, // 设备ID H5 客户端生成，存储在本地缓存
    unionid: option.unionid ? option.unionid : '',
    channel: 'yuetao', // 渠道 悦淘(yuetao)、直订(zhiding)、邻居团(linjutuan)
    platform: 'h5', // 渠道 app、miniprogram、 h5
    entrance: option.entrance ? option.entrance : 'h5_invite', // 入口 app， app_scan扫码， h5_scan 海报扫码, h5_invite 邀请, h5_buy_link 购买链接, miniprogram_ydt小程序 悦地摊儿, miniprogram_yt悦淘商城, miniprogram_local_service本地生活
    os: appInfo.systemType, // 操作系统 ios, andorid
    os_version: appInfo.systemVersion ? appInfo.systemVersion : '', // 系统版本
    browser: browserInfo ? browserInfo.browser : '', // 浏览器内核
    browser_version: browserInfo ? browserInfo.browser_version : '', // 浏览器版本
    Inviter: option.Inviter ? option.Inviter : '', // 邀请人
    company: option.company ? option.company : '' // 所属分公司
  }
  if (option.product) {
    obj.product = option.product
  }
  return obj
}

// 友盟埋点

export function getUWebInfo(option) {
  // const browserInfo = getBrowserInfo()
  const appInfo = setAppInfo()

  const obj = {
    'd': appInfo.uuid, // 设备标识, DeviceID
    'a': 'yuetao', // app渠道 悦淘(yuetao)、直订(zhiding)、邻居团(linjutuan)、大人(daren)
    'c': 'h5', // 方式 app, 小程序minApp, h5
    'p': '', // LONG, 页面ID
    'u': option && option.uid ? option.uid : '', // LONG, 加密后的用户ID
    'pr': option && option.goodId ? option.goodId : '', // LONG, 加密后的商品ID
    'ct': 444, // Int, 加密后的商品类别，一级类目
    'sku': option && option.skuId ? option.skuId : '', // skuid，
    'b': option && option.type ? option.type : 'pv', // String, 用户行为类型, 包括('pv', 'buy', 'cart', 'fav', 'share')
    'cc': 'xxx', // 商品渠道， 包括('jd', 'tb', 'pdd', 'tmall',)
    't': parseInt(new Date().getTime() / 1000) // 时间戳，单位秒
  }
  return obj
}
