/*
 * @Author: your name
 * @Date: 2020-10-12 17:45:26
 * @LastEditTime: 2021-06-04 05:17:44
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /yue_quanzhan_h5/src/routers/moudles/goodsDetailPublic.js
 */
export default [
  {
    path: '/goodsDetailZY',
    name: 'goodsDetailZY',
    component: () =>
      import ('@/views/goodsDetail/goods-detail-zy'), // 懒加载方式
    meta: {
      title: '商品详情' // 自营详情
    }
  },
  {
    path: '/goodsDetailMS',
    name: 'goodsDetailMS',
    component: () =>
      import ('@/views/goodsDetail/goods-detail-ms'), // 懒加载方式
    meta: {
      title: '商品详情' // 秒杀详情
    }
  },
  {
    path: '/goodsDetailCPS',
    name: 'goodsDetailCPS',
    component: () =>
      import ('@/views/goodsDetail/goods-detail-cps'), // 懒加载方式
    meta: {
      title: '商品详情', // 京东详情
      requireAuth: true // 进入商品详情必须登录
    }
  },
  {
    path: '/goodsDetailXm',
    name: 'goodsDetailXm',
    component: () =>
      import ('@/views/goodsDetail/goods-detail-xm'), // 懒加载方式
    meta: {
      title: '商品详情', // 京东详情
      requireAuth: true // 进入商品详情必须登录
    }
  },
  {
    path: '/goodsDetailPT',
    name: 'goodsDetailPT',
    component: () =>
      import ('@/views/goodsDetail/goods-detail-pt'), // 懒加载方式
    meta: {
      title: '开团免费拿' // 开团免费拿
    }
  },

  {
    path: '/goodsDetailXSBJ',
    name: 'goodsDetailXSBJ',
    component: () =>
      import ('@/views/goodsDetail/goods-detail-xsbj'), // 懒加载方式
    meta: {
      title: '限时详情' // 限时详情
    }
  },

  {
    path: '/googCommentList',
    name: 'googCommentList',
    component: () =>
      import ('@/views/goodsDetail/googCommentList'), // 懒加载方式
    meta: {
      title: '评论列表' // 限时详情
    }
  },

  {
    path: '/growCount',
    name: 'growCount',
    component: () =>
      import ('@/views/goodsDetail/grow-count'), // 懒加载方式
    meta: {
      title: '预估成长值'
    }
  },
  {
    path: '/assessList',
    name: 'assessList',
    component: () =>
      import ('@/views/goodsDetail/assess-list'), // 懒加载方式
    meta: {
      title: '评论列表'
    }
  },

  // 老版本购物车
  {
    path: '/shopCar',
    name: 'shopCar',
    component: () =>
      import ('@/views/shopcar/goShopCar'), // 懒加载方式  （shopcar 老的）
    meta: {
      title: '购物车',
      requireAuth: true
    }
  },
  {
    path: '/shopcarlist',
    name: 'shopcarlist',
    component: () =>
      import ('@/views/shopcar/shopcarlist'), // 懒加载方式
    meta: {
      title: '购物车',
      requireAuth: true
    }
  },
  // 新版购物车
  {
    path: '/goShopCar',
    name: 'goShopCar',
    component: () =>
      import ('@/views/shopcar/goShopCar'), // 懒加载方式
    meta: {
      title: '购物车',
      requireAuth: true
    }
  },

  //  刘同版
  {
    path: '/newshopcar',
    name: 'newshopcar',
    component: () =>
      import ('@/views/shopcar/newshopcar'), // 懒加载方式
    meta: {
      title: '购物车',
      requireAuth: true
    }
  },

  {
    path: '/goodsSearch',
    name: 'goodsSearch',
    component: () =>
      import ('@/views/goods/goods-search'), // 懒加载方式
    meta: {
      title: '搜索结果页'
    }
  },
  {
    path: '/blank',
    name: 'blank',
    component: () =>
      import ('@/views/blank/index'),
    meta: {
      title: '',
      requireAuth: false
    }
  },
  {
    path: '/newPeopleList',
    name: 'newPeopleList',
    component: () =>
      import ('@/views/homePopup/newPeopleList'),
    meta: {
      title: '新人专享'
    }
  },
  {
    path: '/nine',
    name: 'nine',
    component: () =>
      import ('@/views/homePopup/nine'),
    meta: {
      title: '9.9三件专场'
    }
  },
  {
    path: '/zeroOne',
    name: 'zeroOne',
    component: () =>
      import ('@/views/homePopup/zeroOne'),
    meta: {
      title: '0.01元购物福利'
    }
  },

  {
    path: '/invoice',
    name: 'invoice',
    component: () =>
      import ('@/views/invoice/invoice'),
    meta: {
      title: '开发票'
    }
  },

  {
    path: '/invoiceSon',
    name: 'invoiceSon',
    component: () =>
      import ('@/views/invoice/invoiceSon'),
    meta: {
      title: '开发票'
    }
  },
  {
    path: '/addInvoice',
    name: 'addInvoice',
    component: () =>
      import ('@/views/invoice/addInvoice'),
    meta: {
      title: '开发票'
    }
  },
  // {
  //     path: "/modifyInvoice",
  //     name: "modifyInvoice",
  //     component: () =>
  //         import ("@/views/invoice/modifyInvoice"),
  //     meta: {
  //         title: "开发票",
  //     },
  // },
  {
    path: '/invoiceTest',
    name: 'invoiceTest',
    component: () =>
      import ('@/views/invoice/invoiceTest'),
    meta: {
      title: '开发票'
    }
  }
]
