export default [
  {
    path: '/strategy',
    name: 'strategy',
    component: () => import('@/views/strategy'),
    meta: {
      title: '攻略',
      requireAuth: false,
      keepAlive: false
    }
  },
  {
    path: '/oderSky',
    name: 'odersky',
    component: () => import('@/views/user/oderSky'),
    meta: {
      title: '支付结果',
      requireAuth: false,
      keepAlive: false
    }
  },
  {
    path: '/oderdetail',
    name: 'oderdetail',
    component: () => import('@/views/user/oderdetail'),
    meta: {
      title: '支付结果',
      requireAuth: true,
      keepAlive: false
    }
  },
  {
    path: '/user',
    name: 'user',
    component: () => import('@/views/user'),
    meta: {
      title: '个人中心',
      requireAuth: true,
      keepAlive: true
    }
  },
  {
    path: '/prerogativePay',
    name: 'prerogativePay',
    component: () => import('@/views/user/prerogativePay'),
    meta: {
      title: '特权充值',
      requireAuth: false,
      keepAlive: false
    }
  },
  {
    path: '/placeOder',
    name: 'placeOder',
    component: () => import('@/views/user/placeOder'),
    meta: {
      title: '提交订单',
      requireAuth: false,
      keepAlive: false
    }
  },
  {
    path: '/placeModer',
    name: 'placeModer',
    component: () => import('@/views/user/placeModer'),
    meta: {
      title: '提交订单',
      requireAuth: false,
      keepAlive: false
    }
  },
  {
    path: '/placeCaroder',
    name: 'placeCaroder',
    component: () => import('@/views/user/placeCaroder'),
    meta: {
      title: '提交订单',
      requireAuth: false,
      keepAlive: false
    }
  },
  {
    path: '/coupon',
    name: 'coupon',
    component: () => import('@/views/user/coupon'),
    meta: {
      title: '优惠券',
      requireAuth: false,
      keepAlive: false
    }
  },
  {
    path: '/addressList',
    name: 'addressList',
    component: () => import('@/views/user/addressList'),
    meta: {
      title: '选择地址',
      requireAuth: false,
      keepAlive: false
    }
  },
  {
    path: '/editAddress',
    name: 'editAddress',
    component: () => import('@/views/user/editAddress'),
    meta: {
      title: '编辑地址',
      requireAuth: false,
      keepAlive: false
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/login'),
    meta: {
      title: '登录',
      requireAuth: false,
      keepAlive: false
    }
  },
  {
    path: '/slogin',
    name: 'slogin',
    component: () => import('@/views/login/slogin'),
    meta: {
      title: '注册',
      requireAuth: false,
      keepAlive: false
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/login/sharelogin'),
    meta: {
      title: '注册',
      requireAuth: false,
      keepAlive: false
    }
  },
  {
    path: '/setting',
    name: 'setting',
    component: () => import('@/views/user/setting'),
    meta: {
      title: '设置',
      requireAuth: true,
      keepAlive: true
    }
  },
  {
    path: '/couoderlist',
    name: 'couoderlist',
    component: () => import('@/views/user/couOrderlist'),
    meta: {
      title: '白拿凑单列表',
      requireAuth: true,
      keepAlive: true
    }
  },
  {
    path: '/cpsopo',
    name: 'cpsopo',
    component: () => import('@/views/activity/Cpsopo'),
    meta: {
      title: '活动',
      // requireAuth: true,
      keepAlive: true
    }
  },
  {
    path: '/mywallet',
    name: 'mywallet',
    component: () => import('@/views/user/mywallet'),
    meta: {
      title: '我的钱包',
      requireAuth: true,
      keepAlive: true
    }
  },
  {
    path: '/Settlement',
    name: 'Settlement',
    component: () => import('@/views/user/Settlement_records'),
    meta: {
      title: '结算记录',
      requireAuth: true,
      keepAlive: true
    }
  },
  {
    path: '/Withdrawal',
    name: 'Withdrawal',
    component: () => import('@/views/user/Withdrawal'),
    meta: {
      title: '提现',
      requireAuth: true,
      keepAlive: true
    }
  },
  {
    path: '/consumption',
    name: 'consumption',
    component: () => import('@/views/user/consumption'),
    meta: {
      title: '账户余额',
      requireAuth: true,
      keepAlive: true
    }
  },
  {
    path: '/Transactiondetails',
    name: 'Transactiondetails',
    component: () => import('@/views/user/Transactiondetails'),
    meta: {
      title: '交易明细',
      requireAuth: true,
      keepAlive: true
    }
  },
  {
    path: '/commissionIn',
    name: 'commissionIn',
    component: () => import('@/views/user/commissionIn'),
    meta: {
      title: '佣金转入',
      requireAuth: true,
      keepAlive: true
    }
  },
  {
    path: '/Reag',
    name: 'Reag',
    component: () => import('@/views/user/Reag'),
    meta: {
      title: '充值协议',
      requireAuth: true,
      keepAlive: true
    }
  },
  {
    path: '/Purchase',
    name: 'Purchase',
    component: () => import('@/views/user/Purchase_settlement'),
    meta: {
      title: '采购结算',
      requireAuth: true,
      keepAlive: true
    }
  },
  {
    path: '/sale',
    name: 'sale',
    component: () => import('@/views/user/sale'),
    meta: {
      title: '销售结算',
      requireAuth: true,
      keepAlive: true
    }
  },
  {
    path: '/commodity',
    name: 'commodity',
    component: () => import('@/views/user/commodity'),
    meta: {
      title: '商品结算',
      requireAuth: true,
      keepAlive: true
    }
  },
  {
    path: '/setup',
    name: 'setup',
    component: () => import('@/views/user/setup'),
    meta: {
      title: '设置',
      requireAuth: true,
      keepAlive: true
    }
  },
  {
    path: '/myinvitation',
    name: 'myinvitation',
    component: () => import('@/views/user/myinvitation'),
    meta: {
      title: '我的邀请',
      requireAuth: true,
      keepAlive: true
    }
  },
  {
    path: '/Interposition',
    name: 'Interposition',
    component: () => import('@/views/user/Interposition'),
    meta: {
      title: '我的间推',
      requireAuth: true,
      keepAlive: true
    }
  },
  {
    path: '/myYuedou',
    name: 'myYuedou',
    component: () => import('@/views/user/myYuedou'),
    meta: {
      title: '我的悦豆',
      requireAuth: true,
      keepAlive: true
    }
  },
  {
    path: '/QRcode',
    name: 'QRcode',
    component: () => import('@/views/user/QRcode'),
    meta: {
      title: '我的二维码',
      requireAuth: true,
      keepAlive: true
    }
  },
  {
    path: '/Browsinghistory',
    name: 'Browsinghistory',
    component: () => import('@/views/user/browsing-history'),
    meta: {
      title: '浏览记录',
      requireAuth: true,
      keepAlive: true
    }
  },
  {
    path: '/myCollection',
    name: 'myCollection',
    component: () => import('@/views/user/myCollection'),
    meta: {
      title: '我的收藏',
      requireAuth: true,
      keepAlive: true
    }
  },
  {
    path: '/Collectionhotel',
    name: 'Collectionhotel',
    component: () => import('@/views/user/Collection/hotel'),
    meta: {
      title: '酒店',
      requireAuth: true,
      keepAlive: true
    }
  },
  {
    path: '/perinfo',
    name: 'perinfo',
    component: () => import('@/views/user/perinfo'),
    meta: {
      title: '会员信息',
      requireAuth: true,
      keepAlive: true
    }
  },
  {
    path: '/Changename',
    name: 'Changename',
    component: () => import('@/views/user/member/Changename'),
    meta: {
      title: '修改姓名',
      requireAuth: true,
      keepAlive: true
    }
  },
  {
    path: '/nickname',
    name: 'nickname',
    component: () => import('@/views/user/member/nickname'),
    meta: {
      title: '修改昵称',
      requireAuth: true,
      keepAlive: true
    }
  },
  {
    path: '/Certificate',
    name: 'Certificate',
    component: () => import('@/views/user/member/Certificate'),
    meta: {
      title: '证件号',
      requireAuth: true,
      keepAlive: true
    }
  },
  {
    path: '/Cloginpas',
    name: 'Cloginpas',
    component: () => import('@/views/user/member/Cloginpas'),
    meta: {
      title: '修改登录密码',
      requireAuth: true,
      keepAlive: true
    }
  },
  {
    path: '/Infoauthentication',
    name: 'Infoauthentication',
    component: () => import('@/views/user/member/Infoauthentication'),
    meta: {
      title: '信息认证',
      requireAuth: true,
      keepAlive: true
    }
  },
  {
    path: '/myorder',
    name: 'myorder',
    component: () => import('@/views/user/myorder'),
    meta: {
      title: '我的订单',
      requireAuth: true,
      keepAlive: true
    }
  },
  // 订单
  {
    path: '/liveorder',
    name: 'liveorder',
    component: () => import('@/views/user/order/liveorder'),
    meta: {
      title: '直播订单',
      requireAuth: true,
      keepAlive: true
    }
  },
  {
    path: '/Shopping',
    name: 'Shopping',
    component: () => import('@/views/user/order/Shopping'),
    meta: {
      title: '商城订单',
      requireAuth: true,
      keepAlive: true
    }
  },
  {
    path: '/halfprice',
    name: 'halfprice',
    component: () => import('@/views/user/order/halfprice'),
    meta: {
      title: '限时半价',
      requireAuth: true,
      keepAlive: true
    }
  },
  {
    path: '/seckill',
    name: 'seckill',
    component: () => import('@/views/user/order/seckill'),
    meta: {
      title: '秒杀订单',
      requireAuth: true,
      keepAlive: true
    }
  },
  {
    path: '/Bargaining',
    name: 'Bargaining',
    component: () => import('@/views/user/order/Bargaining'),
    meta: {
      title: '我的助力',
      requireAuth: true,
      keepAlive: true
    }
  },
  {
    path: '/Groupwork',
    name: 'Groupwork',
    component: () => import('@/views/user/order/Groupwork'),
    meta: {
      title: '拼团订单',
      requireAuth: true,
      keepAlive: true
    }
  },
  {
    path: '/integral',
    name: 'integral',
    component: () => import('@/views/user/order/integral'),
    meta: {
      title: '积分商城',
      requireAuth: true,
      keepAlive: true
    }
  },
  {
    path: '/retailers',
    name: 'retailers',
    component: () => import('@/views/user/order/retailers'),
    meta: {
      title: '商城订单',
      requireAuth: true,
      keepAlive: true
    }
  },
  {
    path: '/hotel',
    name: 'hotel',
    component: () => import('@/views/user/order/hotel'),
    meta: {
      title: '酒店订单',
      requireAuth: true,
      keepAlive: true
    }
  },
  {
    path: '/explain',
    name: 'explain',
    component: () => import('@/views/user/order/explain'),
    meta: {
      title: '常见问题说明',
      requireAuth: true,
      keepAlive: true
    }
  },
  {
    path: '/comeon',
    name: 'comeon',
    component: () => import('@/views/user/order/comeon'),
    meta: {
      title: '加油订单',
      requireAuth: true,
      keepAlive: true
    }
  },
  {
    path: '/planeticket',
    name: 'planeticket',
    component: () => import('@/views/user/order/planeticket'),
    meta: {
      title: '机票订单',
      requireAuth: true,
      keepAlive: true
    }
  },
  {
    path: '/train',
    name: 'train',
    component: () => import('@/views/user/order/train'),
    meta: {
      title: '火车票订单',
      requireAuth: true,
      keepAlive: true
    }
  },
  {
    path: '/cinematicket',
    name: 'cinematicket',
    component: () => import('@/views/user/order/cinematicket'),
    meta: {
      title: '电影票订单',
      requireAuth: true,
      keepAlive: true
    }
  },
  {
    path: '/Orderdetails',
    name: 'Orderdetails',
    component: () => import('@/views/user/order/Orderdetails'),
    meta: {
      title: '商城订单',
      requireAuth: true,
      keepAlive: true
    }
  },
  {
    path: '/finance',
    name: 'finance',
    component: () => import('@/views/user/order/finance'),
    meta: {
      title: '金融服务订单',
      requireAuth: true,
      keepAlive: true
    }
  },
  {
    path: '/Travel',
    name: 'Travel',
    component: () => import('@/views/user/order/Travel'),
    meta: {
      title: '曹操出行订单',
      requireAuth: true,
      keepAlive: true
    }
  },
  {
    path: '/doctor',
    name: 'doctor',
    component: () => import('@/views/user/order/doctor'),
    meta: {
      title: '好大夫订单',
      requireAuth: true,
      keepAlive: true
    }
  },
  {
    path: '/line',
    name: 'line',
    component: () => import('@/views/user/order/line'),
    meta: {
      title: '线路订单',
      requireAuth: true,
      keepAlive: true
    }
  },
  {
    path: '/logistics',
    name: 'logistics',
    component: () => import('@/views/user/order/logistics'),
    meta: {
      title: '订单查询',
      requireAuth: true,
      keepAlive: true
    }
  },
  {
    path: '/upemail',
    name: 'upemail',
    component: () => import('@/views/user/member/upemail'),
    meta: {
      title: '绑定邮箱',
      requireAuth: true,
      keepAlive: true
    }
  },
  {
    path: '/evaluate',
    name: 'evaluate',
    component: () => import('@/views/user/order/evaluate'),
    meta: {
      title: '评价晒单',
      requireAuth: true,
      keepAlive: true
    }
  },
  {
    path: '/refund',
    name: 'refund',
    component: () => import('@/views/user/order/refund'),
    meta: {
      title: '申请退款',
      requireAuth: true,
      keepAlive: true
    }
  },
  {
    path: '/Shopping_details',
    name: 'Shopping_details',
    component: () => import('@/views/user/order/Shopping_details'),
    meta: {
      title: '拼购详情',
      requireAuth: true,
      keepAlive: true
    }
  },
  {
    path: '/Spelldetail',
    name: 'Spelldetail',
    component: () => import('@/views/user/order/Spelldetail'),
    meta: {
      title: '拼团详情',
      requireAuth: true,
      keepAlive: true
    }
  }
]
