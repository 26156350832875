
export default [
  {
    path: '/universal-studios-order',
    name: 'UniversalStudios',
    component: () => import ('@/views/user/order/universal-studios'), // 订单列表
    meta: {
      title: '环球影城订单'
    }
  }
]
