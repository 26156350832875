<template>
  <div id="app">
    <div
      v-if="wechat"
      ref="dragBox"
      class="weixin"
      @click="follow"
      @touchmove="touchmoveHandle('dragBox', $event)"
      @touchstart="touchstartHandle('dragBox', $event)"
    >
      <div>
        <img src="./assets/images/daohang-gongzhonghaotixing.png" alt="">
      </div>
      <span> 关注公众号</span>
    </div>
    <router-view />
    <div v-show="$store.state.showLoading" class="loading-mask">
      <div class="pic-box">
        <img class="pic" src="./assets/images/dragon.gif">
      </div>
    </div>
  </div>
</template>

<script>
import { isWeChat, isWeChatApplet } from '@/utils/common/validate'

export default {
  name: 'App',
  data() {
    return {
      wechat: false,
      coordinate: {
        client: {},
        elePosition: {}
      }
    }
  },
  computed: {},
  created() {
  },
  mounted() {
    // 监听scroll事件
    const that = this
    // 友盟统计添加
    this.$nextTick(() => {
      const script = document.createElement('script')
      script.src = `https://s9.cnzz.com/z_stat.php?id=1279441928&web_id=1279441928"`
      script.language = 'JavaScript'
      script.id = 'cnzz'
      document.body.appendChild(script)
    })

    this.$store.dispatch('user/isFollowChange').then((res) => {
      this.wechat = !isWeChatApplet() && isWeChat() && res
      this.$nextTick(() => {
        if (res === true) {
          -window.addEventListener(
            'scroll',
            function(e) {
              that.handleScroll(e)
            },
            true
          )
        }
      })
    })
  },
  destroyed() {
    // 页面离开后销毁，防止切换路由后上一个页面监听scroll滚动事件会在新页面报错问题
    window.removeEventListener('scroll', this.handleScroll)
  },

  methods: {
    handleScroll(e) {
      // 滚动条在y轴上的滚动距离
      const scrollTop = e.target.scrollTop
      const accountEl = document.getElementsByClassName('weixin')[0]
      // 滚动条距离页面顶部的距离大于一屏时触发该方法
      if (scrollTop <= 5) {
        accountEl.style.right = 0 + 'px'
      } else {
        accountEl.style.right = -20 + '%'
      }
    },
    follow() {
      window.location.href =
        'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzIxOTAzNDI0MA==&scene=124#wechat_redirect'
    },
    touchstartHandle(refName, e) {
      const element = e.targetTouches[0]
      // 记录点击的坐标
      this.coordinate.client = {
        x: element.clientX,
        y: element.clientY
      }
      // 记录需要移动的元素坐标
      this.coordinate.elePosition.left = this.$refs[refName].offsetLeft
      this.coordinate.elePosition.top = this.$refs[refName].offsetTop
    },
    touchmoveHandle(refName, e) {
      const element = e.targetTouches[0]
      // 根据初始 client 位置计算移动距离(元素移动位置=元素初始位置+光标移动后的位置-光标点击时的初始位置)
      // let x = this.coordinate.elePosition.left + (element.clientX - this.coordinate.client.x)
      let y =
        this.coordinate.elePosition.top +
        (element.clientY - this.coordinate.client.y)
      // 限制可移动距离，不超出可视区域
      // x = x <= 0 ? 0
      //   : x >= innerWidth - this.$refs[refName].offsetWidth
      //     ? innerWidth - this.$refs[refName].offsetWidth
      //     : x
      y =
        y <= 0
          ? 0
          : y >= innerHeight - this.$refs[refName].offsetHeight
            ? innerHeight - this.$refs[refName].offsetHeight
            : y
      // 移动当前元素
      // this.$refs[refName].style.left = x + "px";
      this.$refs[refName].style.top = y + 'px'
    }
  }
}
</script>

<style lang="less">
html,
body,
#app {
  height: 100%;
  background: #f8f8f8;
}

#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: @colorFont;
  font-size: @fontSizeH3;

  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.2s ease;
  }

  .fade-enter,
  .fade-leave-active {
    opacity: 0;
  }

  .page {
    transition: all 0.8s ease-in-out;
  }

  .page-go-enter-active {
    transform: translate3d(100%, 0, 0);
    // transition: all 0.8s ease-in-out;
  }

  .page-go-enter-to {
    transform: translate3d(0, 0, 0);
    // transition: all 0.8s ease-in-out;
  }

  .page-go-leave-active {
    transform: translate3d(0, 0, 0);
    // transition: all 0.8s ease-in-out;
  }

  .page-go-leave-to {
    transform: translate3d(-100%, 0, 0);
    // transition: all 0.8s ease-in-out;
  }

  .page-back-enter-active {
    transform: translate3d(-100%, 0, 0);
    // transition: all 0.8s ease-in-out;
  }

  .page-back-enter-to {
    transform: translate3d(0, 0, 0);
    // transition: all 0.8s ease-in-out;
  }

  .page-back-leave-active {
    transform: translate3d(0, 0, 0);
    // transition: all 0.8s ease-in-out;
  }

  .page-back-leave-to {
    transform: translate3d(100%, 0, 0);
    // transition: all 0.8s ease-in-out;
  }

  .loading-mask {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.25);
    z-index: 999;

    .pic-box {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 90px;

      .pic {
        display: block;
        width: 100%;
      }

      .txt {
        width: 100%;
        text-align: center;
        font-size: 12px;
        color: #333;
      }
    }
  }

  .weixin {
    // color: #f4a940;
    z-index: 2000;
    background: rgba(254, 235, 235, 1);
    position: fixed;
    width: 113px;
    height: 32px;
    line-height: 36px;
    font-size: 17px;
    top: 100px;
    right: 0;
    border-radius: 19px 0 0 19px;
    transition: 0.3s;
    display: flex;
    justify-content: space-around;
    align-items: center;

    div {
      width: 25px;
      height: 25px;
      display: inline;

      img {
        width: 21px;
        height: 21px;
      }
    }

    span {
      font-size: 14px;
      font-weight: bold;
      color: #f7263c;

      // width: 60%;
    }
  }
}
</style>
