/**
 * 通用方法集合
 */
import { isUrl } from '@/utils/common/validate'

/**
 * 加法函数，用来得到精确的加法结果<br>
 * javascript的加法结果会有误差，在两个浮点数相加的时候会比较明显。这个函数返回较为精确的加法结果。
 * @param {number} arg1
 * @param {number} arg2
 * @returns {number} arg1加上arg2的精确结果
 * @example accAdd(0.1, 0.2) => 0.3
 */
export function accAdd(arg1, arg2) {
  var r1, r2, m, c

  try {
    r1 = arg1.toString().split('.')[1].length
  } catch (e) {
    r1 = 0
  }
  try {
    r2 = arg2.toString().split('.')[1].length
  } catch (e) {
    r2 = 0
  }
  c = Math.abs(r1 - r2)
  m = Math.pow(10, Math.max(r1, r2))
  if (c > 0) {
    var cm = Math.pow(10, c)
    if (r1 > r2) {
      arg1 = Number(arg1.toString().replace('.', ''))
      arg2 = Number(arg2.toString().replace('.', '')) * cm
    } else {
      arg1 = Number(arg1.toString().replace('.', '')) * cm
      arg2 = Number(arg2.toString().replace('.', ''))
    }
  } else {
    arg1 = Number(arg1.toString().replace('.', ''))
    arg2 = Number(arg2.toString().replace('.', ''))
  }
  return (arg1 + arg2) / m
}

/**
 * 除法函数，用来得到精确的除法结果<br>
 * javascript的除法结果会有误差，在两个浮点数相除的时候会比较明显。这个函数返回较为精确的除法结果。
 * @param {number} arg1
 * @param {number} arg2
 * @returns {number} arg1除以arg2的精确结果
 * @example accDiv(0.2, 0.3) => 0.6666666666666666
 */
export function accDiv(arg1, arg2) {
  var t1 = 0; var t2 = 0; var r1; var r2

  try {
    t1 = arg1.toString().split('.')[1].length
  } catch (e) {
    console.log('accDiv: ', e)
  }
  try {
    t2 = arg2.toString().split('.')[1].length
  } catch (e) {
    console.log('accDiv: ', e)
  }
  r1 = Number(arg1.toString().replace('.', ''))
  r2 = Number(arg2.toString().replace('.', ''))
  return (r1 / r2) * Math.pow(10, t2 - t1)
}

/**
 * 乘法函数，用来得到精确的乘法结果<br>
 * javascript的乘法结果会有误差，在两个浮点数相乘的时候会比较明显。这个函数返回较为精确的乘法结果。
 * @param {number} arg1
 * @param {number} arg2
 * @returns {number} arg1乘以arg2的精确结果
 * @example accMul(0.222, 0.3333) => 0.0739926
 */
export function accMul(arg1, arg2) {
  var m = 0
  var s1 = arg1.toString()
  var s2 = arg2.toString()

  try {
    m += s1.split('.')[1].length
  } catch (e) {
    console.log('accMul: ', e)
  }
  try {
    m += s2.split('.')[1].length
  } catch (e) {
    console.log('accMul: ', e)
  }
  return Number(s1.replace('.', '')) * Number(s2.replace('.', '')) / Math.pow(10, m)
}

/**
 * 减法函数，用来得到精确的减法结果<br>
 * javascript的减法结果会有误差，在两个浮点数相减的时候会比较明显。这个函数返回较为精确的减法结果。
 * @param {number} arg1
 * @param {number} arg2
 * @returns {number} arg1减去arg2的精确结果
 * @example accDiv(0.3, 0.2) => 0.1
 */
export function accSub(arg1, arg2) {
  var r1, r2, m, n

  try {
    r1 = arg1.toString().split('.')[1].length
  } catch (e) {
    r1 = 0
  }
  try {
    r2 = arg2.toString().split('.')[1].length
  } catch (e) {
    r2 = 0
  }
  m = Math.pow(10, Math.max(r1, r2)) // last modify by deeka //动态控制精度长度
  n = (r1 >= r2) ? r1 : r2
  return ((arg1 * m - arg2 * m) / m).toFixed(n)
}

/**
 * 为数字加上单位：万或亿
 *
 * @param {number} number 输入数字.
 * @param {number} decimalDigit 小数点后最多位数，默认为2
 * @return {*} 加上单位后的数字
 * @example
 *
 * addChineseUnit(1000.01)
 * // => 1000.01
 *
 * addChineseUnit(10000)
 * // => 1万
 *
 * addChineseUnit(99000)
 * // => 9.9万
 *
 * addChineseUnit(566000)
 * // => 56.6万
 *
 * addChineseUnit(5660000)
 * // => 566万
 *
 * addChineseUnit(44440000)
 * // => 4444万
 *
 * addChineseUnit(11111000)
 * // => 1111.1万
 *
 * addChineseUnit(444400000)
 * // => 4.44亿
 *
 * addChineseUnit(400000000000000000000000)
 * // => 4000万亿亿
 *
 * addChineseUnit(4000000000000000000000000)
 * // => 4亿亿亿
 */
export function addChineseUnit(number, decimalDigit) {
  if (decimalDigit === void 0) decimalDigit = 2

  var isNeedAbs = false
  if (number < 0) {
    isNeedAbs = true
    number = Math.abs(number)
  }

  var integer = Math.floor(number)
  var digit = getDigit(integer)
  // ['个', '十', '百', '千', '万', '十万', '百万', '千万'];
  var unit = []

  if (digit > 3) {
    var multiple = Math.floor(digit / 8)
    if (multiple >= 1) {
      var tmp = Math.floor(integer / Math.pow(10, 8 * multiple))
      unit.push(addWan(tmp, number, 8 * multiple, decimalDigit))
      for (var i = 0; i < multiple; i++) {
        unit.push('亿')
      }
      if (isNeedAbs) {
        return '-' + unit.join('')
      } else {
        return unit.join('')
      }
    } else {
      if (isNeedAbs) {
        return '-' + addWan(integer, number, 0, decimalDigit)
      } else {
        return addWan(integer, number, 0, decimalDigit)
      }
    }
  } else {
    if (isNeedAbs) {
      return '-' + number
    } else {
      return number
    }
  }
}

/**
 * 添加万信息
 * 2021-06-02 10:31:41
 * @author SnowRock
 * @param integer
 * @param number
 * @param mutiple
 * @param decimalDigit
 * @returns {string|number}
 */
export function addWan(integer, number, mutiple, decimalDigit) {
  var digit = getDigit(integer)
  if (digit > 3) {
    var remainder = digit % 8
    // ‘十万’、‘百万’、‘千万’显示为‘万’
    if (remainder >= 5) {
      remainder = 4
    }
    return Math.floor(number / Math.pow(10, remainder + mutiple - decimalDigit)) / Math.pow(10, decimalDigit) + '万'
  } else {
    return Math.floor(number / Math.pow(10, mutiple - decimalDigit)) / Math.pow(10, decimalDigit)
  }
}

/**
 * 获取整除10的倍数
 * @param integer
 * @returns {number}
 */
export function getDigit(integer) {
  var digit = -1
  while (integer >= 1) {
    digit++
    integer = integer / 10
  }
  return digit
}

/**
 * 自动为股票代码添加市场后缀
 *
 * @param {string} stock 股票代码
 * @returns {string}
 * @example
 *
 * appendStockSuffix('600570')
 * // => '600570.SS'
 */
export function appendStockSuffix(stock) {
  var regSS = /^(((700|730|900|600|601|580)[\d]{3})|60[\d]{4})$/
  var regSZ = /^(((000|002|200|300|080|031)[\d]{3}|00[\d]{4}))$/

  if (regSS.test(stock)) {
    stock = stock + '.SS'
  } else if (regSZ.test(stock)) {
    stock = stock + '.SZ'
  }
  return stock
}

/**
 * 加密算法
 * 1.所有入参加入集合M，参数名做key, 值做value
 * 2.提供的密钥1（字段名appid）与密钥2（字段名secret）两项，以及当前时间戳（字段名time)也加入集合M,
 * 3.将集合M内非空参数值的参数按照参数名ASCII码从小到大排序（字典序）
 * 4.集合M所有值拼接成字符串，转化成UTF-8编码格式的字节数组, 最后需要取MD5码（signature摘要值）
 *
 * @param {object} params
 * @example
 *
 * const params = { mobile: '15858264900', nickname: 'liwb', appkey: 'ertfgdf345435568123454rtoiko5=' };
 *
 * md5(encrypt(params).toUpperCase());
 * // => md5('APPKEY=ERTFGDF34543545=&MOBILE=15858264903&NICKNAME=LIWB')
 */
export function encrypt(params) {
  // 顺序排列key
  var keys = Object.keys(params).sort()
  var str = []

  keys.forEach(function(p) {
    str.push(p + '=' + params[p])
  })

  return str.join('&')
}

/**
 * 将from所有的键值对都添加到to上面去，返回to
 *
 * @param {Object} to
 * @param {Object} from
 * @returns {*}
 * @example
 *
 * const from = {mobile: '15858264903', nickname: 'liwb'};
 * const to = {nickname: 'cklwb'};
 *
 * extend(to, from);
 * // => {nickname: "liwb", mobile: "15858264903"}
 */
export function extend(to, from) {
  var keys = Object.keys(from)
  var i = keys.length
  while (i--) {
    to[keys[i]] = from[keys[i]]
  }
  return to
}

/**
 * 格式化银行卡<br>
 * 用户在输入银行卡号时，需要以4位4位的形式显示，就是每隔4位加个空格，方便用户校对输入的银行卡是否正确<br>
 * **注：**一般数据库里面存的都是不带格式的原始数据，所以提交的时候记得过滤下空格再提交哦。毕竟格式化这种算是表现层，前端展示的时候处理下就好，业务逻辑什么用到的卡号可不是格式化后的呢。<br>
 * 还原`val.replace(/\s/g, '');`
 *
 * @param {string} val
 * @returns {*}
 * @example
 *
 * formatBankCard('6225365271562822');
 * // => 6225 3652 7156 2822
 */
export function formatBankCard(val) {
  if (typeof val !== 'string') {
    throw new Error('val')
  }

  var len = val.length
  var reg = /(\d{4})(?=\d)/g

  if (len < 4) {
    return val
  } else {
    return val.replace(reg, '$1 ')
  }
}

/**
 * 将时间转化为几天前,几小时前，几分钟前
 *
 * @param {number} ms
 * @returns {*}
 * @example
 *
 * formatTimeAgo(1505232000000);
 * // => 1天前
 */
export function formatTimeAgo(ms) {
  ms = parseInt(ms)

  var timeNow = Date.now()
  var diff = (timeNow - ms) / 1000
  var date = new Date()
  var days = Math.round(diff / (24 * 60 * 60))
  var hours = Math.round(diff / (60 * 60))
  var minutes = Math.round(diff / 60)
  var second = Math.round(diff)

  if (days > 0 && days < 2) {
    return days + '天前'
  } else if (days <= 0 && hours > 0) {
    return hours + '小时前'
  } else if (hours <= 0 && minutes > 0) {
    return minutes + '分钟前'
  } else if (minutes <= 0 && second >= 0) {
    return '刚刚'
  } else {
    date.setTime(ms)

    return (date.getFullYear() + '-' + f(date.getMonth() + 1) + '-' + f(date.getDate()) + ' ' + f(date.getHours()) + ':' + f(date.getMinutes()))
  }

  function f(n) {
    return (n < 10) ? '0' + n : n
  }
}

/**
 * 获取指定时间unix时间戳
 *
 * @param {string} time
 * @returns {number}
 * @example
 *
 * formatDateToTimeStamp('20160126 12:00:00');
 * // => 1453780800000
 *
 * formatDateToTimeStamp('2016-01-26 12:00:00');
 * // => 1453780800000
 *
 * formatDateToTimeStamp('2016.01.26 12:00:00');
 * // => 1453780800000
 *
 * formatDateToTimeStamp('20160126');
 * // => 1453737600000
 *
 * formatDateToTimeStamp('2016-01-26 12:00:00.0');
 * // => 1453780800000
 */
export function formatDateToTimeStamp(time) {
  if (typeof time !== 'string') {
    throw new Error('time数据类型必须是string')
  }

  // 2016-05-23 13:58:02.0
  if (time.length > 19) {
    time = time.substring(0, 19)
  }

  var unixTime
  var pattern = /-|\./g
  var year
  var month
  var day
  var reset

  if (pattern.test(time)) {
    unixTime = time.replace(pattern, '/')
  } else {
    // 若无’-‘，则不处理
    if (!~time.indexOf('-')) {
      year = time.slice(0, 4)
      month = time.slice(4, 6)
      day = time.slice(6, 8)
      reset = time.slice(8)
      unixTime = year + '/' + month + '/' + day + reset
    }
  }

  return Math.round(new Date(unixTime).getTime())
}

/**
 * 用符号（默认为逗号）格式化金钱
 *
 * @param {string} val
 * @param {string} symbol 默认`,`
 * @returns {string|*|XML|void}
 * @example formatMoney('1234567890') => 1,234,567,890
 */
export function formatMoney(val, symbol) {
  if (symbol === void 0) symbol = ','

  return val.replace(/\B(?=(\d{3})+(?!\d))/g, symbol)
}

/**
 * 手机号码中间部分替换成指定符号
 *
 * @param {string} phone
 * @param {string} symbol 默认为`*`
 * @returns {string|*|void}
 * @example
 *
 * formatPhone('15858264903');
 * // => 158****4903
 */
export function formatPhone(phone, symbol) {
  if (symbol === void 0) symbol = '****'

  return phone.replace(/(\d{3})\d{4}(\d{4})/, ('$1' + symbol + '$2'))
}

/**
 * 隐藏指定字符范围中的文字
 * @param str {String} 指定支付
 * @param frontLen {Number} 隐藏指定支付范围开始位置
 * @param endLen {Number} 隐藏指定支付范围结束位置
 * @returns {string} 隐藏范围后的字符串
 * @example hideRangeInString('1addddd333312aczxcawfnbgabkjsd', 6, 10) => '1adddd**************fnbgabkjsd'
 */
export function hideRangeInString(str, frontLen, endLen) {
  var len = str.length - frontLen - endLen
  var xing = ''
  for (var i = 0; i < len; i++) {
    xing += '*'
  }
  return str.substring(0, frontLen) + xing + str.substring(str.length - endLen)
}

/**
 * 获取url上指定参数或者空参数
 * 2021-06-02 11:07:44
 * @author SnowRock
 * @param arg 传入的参数
 * @param arg.name 获取参数的名称
 * @param arg.url 指定URL参数
 * @returns {undefined|*}
 */
export function getUrlParams(...arg) {
  const [name, url = window.location.href] = arg

  if (!isUrl(url)) return undefined
  const param = new URL(url).search.substr(1)
  if (!param) {
    return {}
  } else {
    const pArr = param.split('&')
    const res = {}
    for (let i = 0; i < pArr.length; i++) {
      const item = pArr[i].split('=')
      res[item[0]] = item[1] ? decodeURI(item[1]) : null
    }
    return !name ? res : res[name]
  }
}

/**
 * 替换url中对应key的参数
 * @param url
 * @param arg
 * @param arg_val
 * @returns {string|*|string}
 */
export function changeURLArg(url, arg, arg_val) {
  var pattern = arg + '=([^&]*)'
  var replaceText = arg + '=' + arg_val
  if (url.match(pattern)) {
    var tmp = '/(' + arg + '=)([^&]*)/gi'
    // eslint-disable-next-line no-eval
    tmp = url.replace(eval(tmp), replaceText)
    return tmp
  } else {
    if (url.match('[\?]')) {
      return url + '&' + replaceText
    } else {
      return url + '?' + replaceText
    }
  }
}
/**
 * 删除url中参数
 * @param url
 * @param ref
 * @returns {string|*|string}
 */
export function delUrlParam(url, ref) {
  // 如果不包括此参数
  if (url.indexOf(ref) === -1) { return url }

  var arr_url = url.split('?')

  var base = arr_url[0]

  var arr_param = arr_url[1].split('&')

  var index = -1

  for (let i = 0; i < arr_param.length; i++) {
    var paired = arr_param[i].split('=')

    if (paired[0] === ref) {
      index = i
      break
    }
  }

  if (index === -1) {
    return url
  } else {
    arr_param.splice(index, 1)
    return base + '?' + arr_param.join('&')
  }
}

/**
 * 防抖函数
 * @param func 函数
 * @param wait 等待秒数
 * @param immediate 是否立刻执行
 * @returns {_debounce}
 */
export function debounce(func, wait, immediate = true) {
  let timer = null
  const _debounce = function(...args) {
    const context = this
    if (timer) {
      clearTimeout(timer)
    }
    if (immediate) {
      if (!timer) { // null
        func.apply(this, args)
      }
      timer = setTimeout(() => {
        timer = null
      }, wait)
    } else {
      timer = setTimeout(() => {
        func.apply(context, args)
        timer = null
      }, wait)
    }
  }
  _debounce.cancel = function() {
    clearTimeout(timer)
    timer = null
  }
  return _debounce
}

/**
 * 节流函数
 * 2021-06-02 13:45:05
 * @author SnowRock
 * @param func 调用事件
 * @param wait 等待时间
 * @param options 其他额外参数
 * @returns {_throttle}
 */
export function throttle(func, wait, options = {}) {
  let timer = null

  const _throttle = function(...args) {
    const context = this
    if (!timer) {
      timer = setTimeout(() => {
        func.apply(context, args)
        timer = null
      }, wait)
    }
  }
  return _throttle
}

/**
 * 字符串补全长度（从头填充）
 * <br> 如果字符串的长度不够设置的size时，默认填充设置的fillString
 * @param str 字符串
 * @param size 字符串的长度 默认为 0
 * @param fillString 填充的字符 默认为 '0'
 * @returns {string}
 */
export function fillInStartStr(str, size = 0, fillString = '0') {
  return str.padStart(size, fillString)
}

/**
 * 字符串补全长度（末尾补全）
 * <br> 如果字符串的长度不够设置的size时，默认填充设置的fillString
 * @param str 字符串
 * @param size 字符串的长度 默认为 0
 * @param fillString 填充的字符 默认为 '0'
 * @returns {string}
 */
export function fillInEndStr(str, size = 0, fillString = '0') {
  return str.padEnd(size, fillString)
}

/**
 * 将字节转换成友好格式，如Bytes，KB，MB
 *
 * @param {string} bytes
 * @returns {*}
 * @example bytesToSize(10000) => 9.8 KB
 */
export function bytesToSize(bytes) {
  var sizes = ['Bytes', 'KB', 'MB']
  if (bytes === 0) {
    return 'n/a'
  }
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
  return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i]
}

/**
 * base64转blob
 *
 * @param {string} dataURL
 * @returns {*}
 * @example
 *
 * const URI = 'data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTAyNCAxMDI0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMDAiIGhlaWdodD0iMjAwIj48ZGVmcz48c3R5bGUvPjwvZGVmcz48cGF0aCBkPSJNOTU5LjQzNiAyNDMuNUwzNzcuNDEzIDg3MC4yNyA2NC4wMiA0NjcuMzQ0bDExNC43MjctOTcuOTMgMTk4LjY2NiAyMDcuMDYgNDkyLjQ4Mi00MjIuNTEgODkuNTQgODkuNTM3em0wIDAiIGZpbGw9IiM0M2E5ZjEiLz48L3N2Zz4=';
 *
 * dataURLToBlob(URI);
 * // => Blob {size: 248, type: "image/svg+xml"}
 */
export function dataURLToBlob(dataURL) {
  var BASE64_MARKER = ';base64,'
  var parts
  var contentType
  var raw

  if (dataURL.indexOf(BASE64_MARKER) === -1) {
    parts = dataURL.split(',')
    contentType = parts[0].split(':')[1]
    raw = decodeURIComponent(parts[1])

    return new Blob([raw], { type: contentType })
  }

  parts = dataURL.split(BASE64_MARKER)
  contentType = parts[0].split(':')[1]
  raw = window.atob(parts[1])
  var rawLength = raw.length
  var uInt8Array = new Uint8Array(rawLength)

  for (var i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i)
  }

  return new Blob([uInt8Array], { type: contentType })
}

/**
 * 获取设备像素比
 *
 * @returns {number}
 * @example
 *
 * // window.navigator.appVersion(5.0 (iPhone; CPU iPhone OS 9_1 like Mac OS X) AppleWebKit/601.1.46 (KHTML, like Gecko) Version/9.0 Mobile/13B143 Safari/601.1)
 * getPixelRatio();
 * // => 2
 */
export function getPixelRatio() {
  var ctx = document.createElement('canvas').getContext('2d')
  var dpr = window.devicePixelRatio || 1
  var bsr = ctx.webkitBackingStorePixelRatio ||
    ctx.mozBackingStorePixelRatio ||
    ctx.msBackingStorePixelRatio ||
    ctx.oBackingStorePixelRatio ||
    ctx.backingStorePixelRatio || 1

  return dpr / bsr
}

/**
 * 将文本插入到文本区域的光标位置<br>
 * _应用场景：_如在评论框里，在光标位置里插入emoji等
 *
 * @param {object} dom对象
 * @param {string} str
 * @example
 *
 * <textarea name="textarea" rows="10" cols="50">你好世界~</textarea>
 *
 * const editText = document.querySelector('#editText');
 *
 * insertText(editText, 'hello world');
 * // =>
 */
export function insertAtCaret(dom, str) {
  if (str === void 0) str = ''

  if (document.selection) { // IE
    var sel = document.selection.createRange()
    sel.text = str
  } else if (typeof dom.selectionStart === 'number' && typeof dom.selectionEnd === 'number') {
    var startPos = dom.selectionStart
    var endPos = dom.selectionEnd
    var cursorPos = startPos
    var tmpStr = dom.value

    dom.value = tmpStr.substring(0, startPos) + str + tmpStr.substring(endPos, tmpStr.length)
    cursorPos += str.length
    dom.selectionStart = dom.selectionEnd = cursorPos
  } else {
    dom.value += str
  }
}

/**
 * 得到两个时间的时间差（返回天数）
 *
 * @since 1.0.7
 * @param {number} startDay 开始时间戳
 * @param {number} endDay   结束时间戳
 * @returns {number}
 * @example
 *
 * getDiffDay(1501516800000, 1504195200000);
 * // => 31
 */
export function getDiffDay(startDay, endDay) {
  startDay = Number(startDay)
  endDay = Number(endDay)
  return Math.abs(endDay - startDay) / (24 * 1000 * 3600)
}

/**
 * dom操作，元素是包含某个class
 *
 * @since 1.1.5
 * @param el HTML元素
 * @param cls css类名
 * @returns {boolean}
 * @example
 *
 * <div class="box flex"></div>
 * hasClass(document.querySelector('.box'), 'flex');
 * // => true
 */
export function hasClass(el, cls) {
  if (!el || !cls) {
    return false
  }
  if (cls.indexOf(' ') !== -1) {
    throw new Error('className should not contain space.')
  }
  if (el.classList) {
    return el.classList.contains(cls)
  } else {
    return (' ' + el.className + ' ').indexOf(' ' + cls + ' ') > -1
  }
}

/**
 * dom操作，元素添加某个class
 *
 * @since 1.1.5
 * @param el HTML元素
 * @param cls css类名
 * @example
 *
 * <div class="box flex"></div>
 * addClass(document.querySelector('.box'), 'flex1');
 * // => <div class="box flex flex1"></div>
 */
export function addClass(el, cls) {
  if (!el) {
    return
  }
  var curClass = el.className
  var classes = (cls || '').split(' ')

  for (var i = 0, j = classes.length; i < j; i++) {
    var clsName = classes[i]
    if (!clsName) {
      continue
    }

    if (el.classList) {
      el.classList.add(clsName)
    } else {
      if (!hasClass(el, clsName)) {
        curClass += ' ' + clsName
      }
    }
  }
  if (!el.classList) {
    el.className = curClass
  }
}

var trim = function(string) {
  return (string || '').replace(/^[\s\uFEFF]+|[\s\uFEFF]+$/g, '')
}

/**
 * dom操作，元素删除某个class
 *
 * @since 1.1.5
 * @param el HTML元素
 * @param cls css类名
 * @example
 *
 * <div class="box flex"></div>
 * removeClass(document.querySelector('.box'), 'flex');
 * // => <div class="box"></div>
 */
export function removeClass(el, cls) {
  if (!el || !cls) {
    return
  }
  var classes = cls.split(' ')
  var curClass = ' ' + el.className + ' '

  for (var i = 0, j = classes.length; i < j; i++) {
    var clsName = classes[i]
    if (!clsName) {
      continue
    }

    if (el.classList) {
      el.classList.remove(clsName)
    } else {
      if (hasClass(el, clsName)) {
        curClass = curClass.replace(' ' + clsName + ' ', ' ')
      }
    }
  }
  if (!el.classList) {
    el.className = trim(curClass)
  }
}

/**
 * 中划线转换小驼峰
 *
 * @since 1.1.7
 * @param {string} variable
 * @returns {string}
 * @example
 *
 * toCamelCaseVar('get_account_list');
 * // => getAccountList
 */
export function toCamelCaseVar(variable) {
  return variable.replace(/_+[a-zA-Z]/g, function(str, index) {
    return index ? str.substr(-1).toUpperCase() : str
  })
}

/**
 * 格式化数字、金额、千分位、保留几位小数、舍入舍去
 *
 * @since 1.0.7
 * @param number 要格式化的数字
 * @param decimals 保留几位小数
 * @param decPoint 小数点符号
 * @param thousandsSep 千分位符号
 * @param roundTag 舍入参数，默认 'ceil' 向上取,'floor'向下取,'round' 四舍五入
 * @returns {XML|void|*|string}
 * @example
 *
 * formatNumber(2, 2, '.', ',');
 * // => 2.00
 */
export function formatNumber(number, decimals, decPoint, thousandsSep, roundTag) {
  number = (number + '').replace(/[^0-9+-Ee.]/g, '')
  roundTag = roundTag || 'ceil' // 'ceil','floor','round'
  var n = !isFinite(+number) ? 0 : +number
  var prec = !isFinite(+decimals) ? 0 : Math.abs(decimals)
  var sep = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep
  var dec = (typeof decPoint === 'undefined') ? '.' : decPoint
  var re = /(-?\d+)(\d{3})/
  var s = ''
  var toFixedFix = function(n, prec) {
    var k = Math.pow(10, prec)
    return '' + parseFloat(Math[roundTag](parseFloat((n * k).toFixed(prec * 2))).toFixed(prec * 2)) / k
  }
  s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.')
  while (re.test(s[0])) {
    s[0] = s[0].replace(re, '$1' + sep + '$2')
  }
  if ((s[1] || '').length < prec) {
    s[1] = s[1] || ''
    s[1] += new Array(prec - s[1].length + 1).join('0')
  }
  return s.join(dec)
}

/**
 * 版本比较
 *
 * @param oldVersion 老版本
 * @param newVersion 新版本
 * @param containEqual 是否包含相等的情况
 * @returns {boolean} newVersion >[=] oldVersion
 * @example
 *
 * compareVersion('1.0.0', '1.0.1')
 */
export function compareVersion(oldVersion, newVersion, containEqual) {
  if (typeof oldVersion !== 'string' || typeof newVersion !== 'string') {
    return false
  }
  // 分割字符串为['1', '0', '1']格式
  var oldArray = oldVersion.split('.')
  var newArray = newVersion.split('.')
  var o, n
  // 从左向右对比值，值相同则跳过，不同则返回不同的值
  while (o === n && newArray.length > 0) {
    o = oldArray.shift()
    n = newArray.shift()
  }
  // 返回不同值的比较结果
  if (containEqual) {
    return (n | 0) >= (o | 0)
  } else {
    return (n | 0) > (o | 0)
  }
}

/**
 * 主动防御
 * 对于我们操作的数据，尤其是由 API 接口返回的，时常会有一个很复杂的深层嵌套的数据结构。为了代码的健壮性，很多时候需要对每一层访问都作空值判断，就像这样：
 props.user &&
 props.user.posts &&
 props.user.posts[0] &&
 props.user.posts[0].comments &&
 props.user.posts[0].comments[0]
 代码看起来相当不美观，因此提供了一个非常简洁明了的原生的方式。
 *
 * @param p 属性列表
 * @param o 对象
 * @returns {*} 如果正常访问到，则返回对应的值，否则返回 null。
 * @example
 *
 * var props = {
 *  user: {
 *    post: [{
 *      comments: 'test'
 *    }]
 *  }
 * };
 * getIn(['user', 'post', 0, 'comments'], props);
 * // => test
 */
export function getIn(p, o) {
  return p.reduce(function(xs, x) {
    return (xs && xs[x]) ? xs[x] : null
  }, o)
}

/**
 * RGB 转换为 Hex
 *
 * @since 1.2.0
 * @param r r值
 * @param g g值
 * @param b b值
 * @returns {string} Hex值
 * @example
 * rgbToHex(0,0,0);
 * // => #000000
 */
export function rgbToHex(r, g, b) {
  return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)
}

/**
 * Hex 转换为 Rgb
 *
 * @since 1.2.0
 * @param hex
 * @returns {*}
 * @example
 *
 * hexToRgb("#0033ff").g;
 * // => 51
 */
export function hexToRgb(hex) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  hex = hex.replace(shorthandRegex, function(m, r, g, b) {
    return r + r + g + g + b + b
  })

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null
}

/**
 * Anagrams of string（带有重复项）
 * 使用递归。对于给定字符串中的每个字母，为字母创建字谜。使用map（）将字母与每部分字谜组合，然后使用reduce（）将所有字谜组合到一个数组中，最基本情况是字符串长度等于2或1。
 *
 * @since 1.2.1
 * @param str
 * @returns {*}
 * @example
 *
 * anagrams('abc');
 * // => ['abc','acb','bac','bca','cab','cba']
 */
export function anagrams(str) {
  if (str.length <= 2) {
    return str.length === 2 ? [str, str[1] + str[0]] : [str]
  }

  return str.split('').reduce(function(acc, letter, i) {
    return acc.concat(anagrams(str.slice(0, i) + str.slice(i + 1)).map(function(val) {
      return letter + val
    }))
  }, [])
}

/**
 * 大写每个单词的首字母
 *
 * @since 1.2.1
 * @param str
 * @returns {string|*|void|XML}
 * @example
 *
 * capitalizeEveryWord('hello world!');
 * // => 'Hello World!'
 */
export function capitalizeEveryWord(str) {
  return str.replace(/\b[a-z]/g, function(char) {
    return char.toUpperCase()
  })
}

/**
 * 斐波那契数组生成器
 * 创建一个特定长度的空数组，初始化前两个值（0和1）。使用Array.reduce（）向数组中添加值，后面的一个数等于前面两个数相加之和（前两个除外）。
 *
 * @since 1.2.1
 * @param num
 * @returns {*}
 * @example
 *
 * fibonacci(5);
 * // => [0,1,1,2,3]
 */
export function fibonacci(num) {
  return Array(num).fill(0).reduce(function(acc, val, i) {
    return acc.concat(i > 1 ? acc[i - 1] + acc[i - 2] : i)
  }, [])
}

/**
 * 获取滚动位置
 * 如果已定义，请使用pageXOffset和pageYOffset，否则使用scrollLeft和scrollTop，可以省略el来使用window的默认值。
 *
 * @since 1.2.1
 * @param el
 * @returns {{x: Number, y: Number}}
 * @example
 *
 * getScrollPos();
 * // => {x: 0, y: 200}
 */
export function getScrollPos(el) {
  if (el === void 0) el = window

  return ({
    x: (el.pageXOffset !== undefined) ? el.pageXOffset : el.scrollLeft,
    y: (el.pageYOffset !== undefined) ? el.pageYOffset : el.scrollTop
  })
}

/**
 * 测试函数所花费的时间
 *
 * @since 1.2.1
 * @param callback
 * @returns {*}
 * @example
 *
 * timeTaken(() => Math.pow(2, 10));
 * // => 1024
 */
export function timeTaken(callback) {
  if (typeof callback !== 'function') {
    throw new Error('callback 必须为可执行的函数')
  }
  console.time('timeTaken')
  var r = callback()
  console.timeEnd('timeTaken')

  return r
}

/**
 * 数组转换为键值对的对象
 *
 * @since 1.2.1
 * @param array
 * @returns {*}
 * @example
 *
 * objectFromPairs([['a',1],['b',2]]);
 * // => {a: 1, b: 2}
 */
export function objectFromPairs(array) {
  return Array.isArray(array) && array.reduce(function(a, v) {
    a[v[0]] = v[1]
    return a
  }, {})
}

/**
 * 滚动到顶部
 * 使用document.documentElement.scrollTop或document.body.scrollTop获取到顶部的距离。从顶部滚动一小部分距离。
 使用window.requestAnimationFrame（）来滚动。
 *
 * @since 1.2.1
 * @example
 *
 * scrollToTop();
 */
export function scrollToTop() {
  var c = document.documentElement.scrollTop || document.body.scrollTop

  if (c > 0) {
    window.requestAnimationFrame(scrollToTop)
    window.scrollTo(0, c - c / 8)
  }
}

/**
 * 获取localStorage中包含的参数配置
 * 2021-06-02 18:07:44
 * @author SnowRock
 * @param TokenKey key值
 * @returns {string}
 */
export function getLocal(TokenKey) {
  return localStorage.getItem(TokenKey)
}

/**
 * 设置localStorage
 * 2021-06-02 18:07:44
 * @author SnowRock
 * @param TokenKey key值
 * @param token value值
 */
export function setLocal(TokenKey, token) {
  localStorage.setItem(TokenKey, token)
}

/**
 * 移除指定localStorage
 * 2021-06-02 18:07:44
 * @author SnowRock
 * @param TokenKey key值
 */
export function removeLocal(TokenKey) {
  localStorage.removeItem(TokenKey)
}

/**
 * 移除所有localStorage
 * 2021-06-02 18:09:22
 * @author SnowRock
 */
export function clearLocal() {
  localStorage.clear()
}

/**
 * 获取localStorage中包含的参数配置
 * 2021-06-02 18:07:44
 * @author SnowRock
 * @param TokenKey key值
 * @returns {string}
 */
export function getSession(TokenKey) {
  return sessionStorage.getItem(TokenKey)
}

/**
 * 设置sessionStorage
 * 2021-06-02 18:07:44
 * @author SnowRock
 * @param TokenKey key值
 * @param token value值
 */
export function setSession(TokenKey, token) {
  sessionStorage.setItem(TokenKey, token)
}

/**
 * 移除指定sessionStorage
 * 2021-06-02 18:07:44
 * @author SnowRock
 * @param TokenKey key值
 */
export function removeSession(TokenKey) {
  sessionStorage.removeItem(TokenKey)
}

/**
 * 移除所有sessionStorage
 * 2021-06-02 18:09:22
 * @author SnowRock
 */
export function clearSession() {
  sessionStorage.clear()
}

/**
 * 关键字高亮
 * @param val
 * @param keyword
 * @returns {string}
 * @constructor
 */
export function keyRegExp(val, keyword) {
  val = val + ''
  if (val.indexOf(keyword) !== -1 && keyword !== '') {
    return val.replace(keyword, '<font color="#f00">' + keyword + '</font>')
  } else {
    return val
  }
}

/**
 * 复制所传入的文本
 * 2021-06-10 15:11:40
 * @author SnowRock
 * @param text
 * @param c { function || null} 回调函数
 */
export function copyText(text, c = null) {
  const input = document.createElement('input')
  input.setAttribute('id', 'copyInput')
  input.setAttribute('value', text)
  document.getElementsByTagName('body')[0].appendChild(input)
  document.getElementById('copyInput').select()
  if (document.execCommand('copy')) {
    document.execCommand('copy')
    c && c()
  }
  document.getElementById('copyInput').remove()
}
